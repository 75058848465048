import type { AxiosPromise, AxiosInstance } from "axios";
import type { UserTransformer } from "../types";

type Include = Array<"booking_summary">;

const getUser = (
  include: Include = []
): ((apiInstance: AxiosInstance) => AxiosPromise<UserTransformer>) => (
  apiInstance
) => {
  return apiInstance.get("/v5/me", {
    params: {
      include
    }
  });
};

export default getUser;
