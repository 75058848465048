// @ts-nocheck
import React from "react";
import Typography from "../Typography";

type Props = {
  cypress?: string | null;
  error: string;
  className?: string;
};
const Error = ({ error, className = "", cypress = null }: Props) => (
  <Typography size="secondary" color="error">
    <div className={`c-error ${className}`} data-cy={cypress} role="alert">
      {error}
    </div>
  </Typography>
);

export default Error;
