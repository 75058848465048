const context = {};

export const setRouterContext = (obj) => {
  if (import.meta.env.SSR) {
    throw new Error(
      'Tried to set router content during SSR, instead set the context via '
    );
  }

  Object.assign(context, obj);
};
type Context = {
  jpApiClient: any;
  apolloClient: any;
  getState: any;
  dispatch: any;
  authPromise: any;
  injectReducer: any;
  t: any;
};

export const getRouterContext = (serverContext): Context => {
  if (serverContext) {
    return serverContext;
  }

  return context;
};
