const pagination = {
  merge(existing: any[], incoming: any[], { args }) {
    const merged = existing ? existing.nodes.slice(0) : [];

    // Insert the incoming elements in the right places, according to args.
    const end = args.offset + Math.min(args.limit, incoming.nodes.length);
    for (let i = args.offset; i < end; i += 1) {
      merged[i] = incoming.nodes[i - args.offset];
    }

    return {
      totalCount: incoming.totalCount,
      nodes: merged
    };
  },

  read(existing: any[]) {
    return existing;
  }
};

export default {
  typePolicies: {
    Listing: {
      fields: {
        reviews: pagination
      }
    },
    CheckoutSummary: {
      keyFields: ['quoteId']
    },
    User: {
      fields: {
        listings: pagination
      }
    }
  }
};
