// @ts-nocheck
import React, { lazy, Suspense } from "react";
import styles from "./fontAwesomeIcon.module.scss";

const FontAwesomeIconComponentModule = lazy(
  () => import("../FontAwesomeIconModule")
);
type Props = {
  icon: any;
  className?: string;
};
const Index = ({ icon, className }: Props) => {
  return (
    <Suspense fallback={null}>
      <FontAwesomeIconComponentModule
        icon={icon}
        className={`${styles.base} ${className}`}
      />
    </Suspense>
  );
};
export default Index;
