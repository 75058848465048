import React from 'react';
import { track } from '../../helpers/Tracking/Analytics';

type Props = {
  children: any;
  isFooter?: boolean;
  openInNewTab?: boolean;
  className?: string | null;
};

const ZendeskLink = ({
  children,
  isFooter = false,
  openInNewTab = false,
  className = null
}: Props) => (
  <a
    onClick={() => {
      const eventName = isFooter ? 'click:web-footer:help' : 'click:nav:help';

      track(eventName, {
        page: window.location.pathname.toLowerCase()
      });
    }}
    href="https://support-uk.justpark.com"
    target={openInNewTab ? '_blank' : null}
    rel="noopener noreferrer"
    className={className}
  >
    {children}
  </a>
);

export default ZendeskLink;
