import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import JustParkLogo from '@justpark/ui/src/components/JustParkLogo';
import { Link } from '@not-remix-run/react';
import type { State } from '../../stores/reducer';

type Props = {
  logo?: string;
  logoInverse?: string;
  theme?: string;
};

export const LogoComponent = ({
  logo = null,
  logoInverse = null,
  theme = null
}: Props) => {
  const [isClient, setClient] = useState(false);
  useEffect(() => setClient(true), [setClient]);

  if (!logo) {
    return (
      <Link to="/">
        <JustParkLogo />
      </Link>
    );
  }

  if (!isClient) {
    // Return null so we don't flash between different images on dark headers
    // isClient state forces a rerender as it'll be different than the SSR
    return null;
  }

  const src = theme === 'dark' && logoInverse ? logoInverse : logo;

  return (
    // eslint-disable-next-line @mizdra/layout-shift/require-size-attributes
    <img
      src={src}
      className="c-logo--whitelabel c-logo--whitelabel-dark"
      alt="Powered by JustPark"
    />
  );
};

const mapStateToProps = (state: State) => ({
  logo: state.brand.logo,
  logoInverse: state.brand.logoInverse
});

export default connect(mapStateToProps)(LogoComponent);
