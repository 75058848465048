/* eslint-disable camelcase, import/no-cycle, no-throw-literal */
import {
  createAsyncThunk,
  createSlice,
  createSelector
} from '@reduxjs/toolkit';
import getUser from '@justpark/api/src/requests/getUser';
import googleLoginRequest from '@justpark/api/src/requests/googleLogin';
import facebookLoginRequest from '@justpark/api/src/requests/facebookLogin';
import appleLoginRequest from '@justpark/api/src/requests/appleLogin';
import loginRequest from '@justpark/api/src/requests/login';
import logoutRequest from '@justpark/api/src/requests/logout';
import registerRequest from '@justpark/api/src/requests/register';
import transformIncludes from '@justpark/helpers/src/transformIncludes/transformIncludes';
import getRequestError from '@justpark/api/src/helpers/getRequestError';
import forgotPassword from '@justpark/api/src/requests/forgotPasswordV4';
import passwordResetConfirmed from '@justpark/api/src/requests/passwordResetConfirmed';
import getRequestErrorCode from '@justpark/api/src/helpers/getV4RequestErrorCode';
import superUserRequest from '@justpark/api/src/requests/superUserRequest';
import createPhoneNumber from '@justpark/api/src/requests/createPhoneNumber';
import dbg from 'debug';
import { makeActionCreator } from '../helpers/utils';
import actionsGoogleLogin from '../helpers/googleLogin';
import { actions as facebookActions } from '../middleware/facebookMiddleware';
import { deleteAccount } from './consents';

import { SUBMIT_DETAILS_SUCCESS } from './actions/shared';
import { ampli } from '../helpers/Tracking/amplitude';

const debug = dbg('jp:reducers:auth');

export const VALIDATE_PASSWORD = 'auth/VALIDATE_PASSWORD';
const UPDATE_REG_FORM = 'auth/UPDATE_REG_FORM';
export const CLEAR_API_ERROR = 'auth/CLEAR_API_ERROR';
export const CLEAR_REG_FORM = 'auth/CLEAR_REG_FORM';
export const TOGGLE_DELETE_ACCOUNT = 'auth/TOGGLE_DELETE_ACCOUNT';
type Action =
  | { type: typeof VALIDATE_PASSWORD }
  | { type: typeof UPDATE_REG_FORM }
  | { type: typeof CLEAR_API_ERROR }
  | { type: typeof CLEAR_REG_FORM };

export type UserBookingSummary = {
  totalBookingsMade: number;
  totalAppBookingsMade: number;
  firstBookingDate: string;
  latestBookingDate: string;
};

export type User = {
  id: number;
  email: string;
  hasSeasonTicket: boolean;
  isSpaceOwner: boolean;
  isActiveSpaceOwner: boolean;
  isSuperUser: boolean;
  phone: string | undefined | null;
  hasVerifiedPhoneNumber: boolean;
  hasVerifiedEmail: boolean;
  bookingSummary: UserBookingSummary;
  dateOfBirth?: string;
  hasIncompleteListings?: boolean;
  lastBookingWasInsured: boolean;
};

export type State = {
  loaded: boolean;
  loading: boolean;
  loggingIn: boolean;
  loggingOut: boolean;
  user: User | undefined | null;
  loginError: string | undefined | null;
  registerError: string | undefined | null;
  registerErrorCode: number | undefined | null;
  logoutError: string | undefined | null;
  resetPasswordError: string | undefined | null;
  deletedAccount: boolean;
  facebookLoading: boolean;
  googleLoading: boolean;
  newUser: boolean;
  registration: {
    isDirty: boolean;
    registerValid: boolean;
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phone: string;
    consents: boolean;
  };
  apiError:
    | {
        title: string;
        info?: string;
        code?: number;
      }
    | undefined
    | null;

  passwordError: string;
  passwordUpdated: boolean;
  setNewPasswordLoading: boolean;
  setNewPasswordApiError: string;
  resetPasswordLinkExpired: boolean;
  resetPasswordMessage: 'PHONE' | 'EMAIL' | '';
  addingPhoneNumber: boolean;
  phoneNumberError: string;
  superUserError: string;
  loginSuccess: boolean;
  newUserSuccess: boolean;
};

export const initialState: State = {
  loaded: false,
  loading: false,
  loggingIn: false,
  loggingOut: false,
  user: null,
  loginError: null,
  registerError: null,
  registerErrorCode: null,
  logoutError: null,
  resetPasswordMessage: '',
  deletedAccount: false,
  facebookLoading: false,
  googleLoading: false,
  newUser: false,
  resetPasswordError: null,
  registration: {
    isDirty: false,
    registerValid: false,
    firstName: '',
    lastName: '',
    password: '',
    email: '',
    phone: '',
    countryCode: '44',
    consents: false
  },
  apiError: null,
  passwordError: '',
  passwordUpdated: false,
  setNewPasswordLoading: false,
  setNewPasswordApiError: '',
  resetPasswordLinkExpired: false,
  addingPhoneNumber: false,
  phoneNumberError: '',
  superUserError: '',
  loginSuccess: false,
  newUserSuccess: false
};

const actionContainsUser = (action) =>
  action.payload.data && 'id' in action.payload.data;
const getUserFromAction = (action: Action) => {
  if (action.payload && action.payload.data) {
    const transformed = transformIncludes(action.payload, ['bookingSummary']);
    return transformed.data;
  }

  throw new Error('No user in getUserFromAction');
};

export const INVALID_NAME_ERROR = 1001;
export const INVALID_EMAIL_ERROR = 1002;
export const INVALID_PASSWORD_ERROR = 1003;
export const INVALID_PHONE_ERROR = 1004;

export const validName = (name: string) =>
  name.length > 0 && /[\w\d\s]+/i.test(name) ? '' : INVALID_NAME_ERROR;

export const validEmail = (email: string) =>
  /[^@]+@[^.]+\.\w+/i.test(email) ? '' : INVALID_EMAIL_ERROR;

export const validPassword = (password: string) =>
  password.length >= 8 && /^\S*$/i.test(password) ? '' : INVALID_PASSWORD_ERROR;

export const validPhoneNumber = (p: string) =>
  /\s*(?:[\d]\s*){7,}/i.test(p) ? '' : INVALID_PHONE_ERROR;

const validRegister = ({ nameError, passwordError, emailError }) => {
  debug(
    'validRegister (nameError, passwordError, emailError)',
    nameError,
    passwordError,
    emailError
  );
  return !nameError && !passwordError && !emailError;
};

const validateResetPassword = (
  password: string,
  confirmPassword: string,
  t
) => {
  if (
    password.length >= 8 &&
    /^\S*$/i.test(password) &&
    password === confirmPassword
  ) {
    return '';
  }
  return t(
    'resetPassword:passwordError',
    'Passwords must match, not contain spaces and be at least 8 characters long'
  );
};

const getErrors = ({ firstName, lastName, password, email }) => {
  const validationErrors = {
    nameError: validName(firstName) || validName(lastName),
    passwordError: validPassword(password),
    emailError: validEmail(email)
  };
  debug('getErrors', validationErrors);
  return validationErrors;
};

export const load = createAsyncThunk(
  'auth/getUser',
  async (payload: void, { extra: { jpApiClient } }): any => {
    const includes = ['booking_summary'];

    const request = getUser(includes);
    const response = await jpApiClient(request);

    return response.data;
  }
);

const loginGoogleAsyncThunk = createAsyncThunk(
  'auth/loginGoogle',
  async (payload: void, { extra: { jpApiClient }, rejectWithValue }): any => {
    const { access_token, queryParams, suppressVerificationEmail } = payload;
    const request = googleLoginRequest(
      access_token,
      queryParams,
      suppressVerificationEmail
    );

    try {
      const response = await jpApiClient(request);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const loginFacebookAsyncThunk = createAsyncThunk(
  'auth/loginFacebook',
  async (payload: void, { extra: { jpApiClient }, rejectWithValue }): any => {
    const { accessToken, queryParams, suppressVerificationEmail } = payload;
    const request = facebookLoginRequest(
      accessToken,
      queryParams,
      suppressVerificationEmail
    );

    try {
      const response = await jpApiClient(request);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const loginAppleAsyncThunk = createAsyncThunk(
  'auth/loginApple',
  async (payload: void, { extra: { jpApiClient }, rejectWithValue }): any => {
    const {
      idToken,
      code,
      firstName,
      lastName,
      setStorage,
      suppressVerificationEmail
    } = payload;
    const request = appleLoginRequest(
      idToken,
      code,
      firstName,
      lastName,
      suppressVerificationEmail
    );

    try {
      const response = await jpApiClient(request);

      if (setStorage) {
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export function appleLogin(
  idToken: string,
  code: string,
  firstName: string | undefined | null,
  lastName: string | undefined | null,
  setStorage: boolean,
  suppressVerificationEmail: boolean
): Action {
  return (dispatch) =>
    dispatch(
      loginAppleAsyncThunk({
        idToken,
        code,
        firstName,
        lastName,
        setStorage,
        suppressVerificationEmail
      })
    );
}

export const login = createAsyncThunk(
  'auth/login',
  async (
    { params, email, password, recaptchaToken },
    { rejectWithValue, extra: { jpApiClient } }
  ) => {
    try {
      const request = loginRequest(
        email,
        password,
        recaptchaToken,
        params,
        'booking_summary'
      );
      const response = await jpApiClient(request);
      return response.data;
    } catch (e) {
      return rejectWithValue(getRequestError(e));
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (
    {
      params,
      email,
      firstName,
      lastName,
      password,
      recaptchaToken,
      suppressVerificationEmail,
      consents
    },
    { rejectWithValue, extra: { jpApiClient } }
  ) => {
    try {
      const consentsArray = [
        {
          name: 'marketing',
          accepted: !consents,
          version: 1
        }
      ];

      const registerApi = registerRequest(
        params,
        email,
        firstName,
        lastName,
        password,
        recaptchaToken,
        suppressVerificationEmail,
        consentsArray
      );

      await jpApiClient(registerApi);

      const request = getUser(['booking_summary']);
      const response = await jpApiClient(request);

      return response.data;
    } catch (e) {
      const message = getRequestError(e);
      const code = getRequestErrorCode(e);

      return rejectWithValue({ message, code });
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (email, { rejectWithValue, extra: { jpApiClient } }) => {
    try {
      // NOTE: This uses the V4 reset endpoint as currently V5 does not support reset via phone number
      const request = forgotPassword(email);
      const response = await jpApiClient(request);

      return response.data;
    } catch (e) {
      return rejectWithValue(getRequestError(e));
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (email, { rejectWithValue, extra: { jpApiClient } }) => {
    try {
      const request = logoutRequest();
      return await jpApiClient(request);
    } catch (e) {
      return rejectWithValue(getRequestError(e));
    }
  }
);

const resetPasswordConfirmation = createAsyncThunk(
  'auth/resetPasswordConfirmation',
  async (payload, { rejectWithValue, extra: { jpApiClient } }) => {
    try {
      const { email, key, newPassword, confirmPassword } = payload;
      const request = passwordResetConfirmed(
        email,
        key,
        newPassword,
        confirmPassword
      );

      return await jpApiClient(request);
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const addPhoneNumber = createAsyncThunk(
  'auth/addPhoneNumber',
  async (
    payload: void,
    { getState, extra: { jpApiClient }, rejectWithValue }
  ) => {
    const { countryCode = '', phoneNumber = '' } = payload;
    const delay = 5;

    try {
      const {
        auth: {
          user: { phone }
        }
      } = getState();

      const phoneNumberStr = `${countryCode}${phoneNumber.replace(/ /g, '')}`;

      if (!phoneNumber.trim()) {
        rejectWithValue('Please enter a phone number');
      }

      if (phone === phoneNumberStr) {
        // Number already added to users account
        return Promise.resolve();
      }

      const request = createPhoneNumber(
        countryCode,
        phoneNumber.replace(/ /g, ''),
        delay
      );
      const { data } = await jpApiClient(request);
      return data;
    } catch (caughtError) {
      return rejectWithValue({
        validationError: getRequestError(caughtError)
      });
    }
  }
);

export const requestSuperUser = createAsyncThunk(
  'auth/requestSuperUser',
  async (payload: void, { rejectWithValue, extra: { jpApiClient } }) => {
    try {
      const { type, data } = payload;

      const request = superUserRequest(type, data);
      const response = await jpApiClient(request);

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearPhoneNumberError: (state: State) => ({
      ...state,
      phoneNumberError: ''
    }),
    clearAuth: () => ({
      ...initialState
    }),
    setLastBookingWasInsured: (state: State) => ({
      ...state,
      user: {
        ...state.user,
        lastBookingWasInsured: true
      }
    })
  },
  extraReducers: (builder) => {
    builder.addCase(
      load.pending,
      (state: State): State => {
        debug('LOAD');
        return {
          ...state,
          loading: true,
          loginSuccess: false,
          newUserSuccess: false
        };
      }
    );
    builder.addCase(
      load.fulfilled,
      (state: State, action): State => {
        debug('LOAD_SUCCESS', action);

        return {
          ...state,
          loading: false,
          loaded: actionContainsUser(action),
          user: getUserFromAction(action)
        };
      }
    );
    builder.addCase(
      load.rejected,
      (state: State, action): State => {
        debug('LOAD_FAIL', action);
        return { ...state, loading: false, loaded: false };
      }
    );
    builder.addCase(
      addPhoneNumber.pending,
      (state: State): State => {
        debug(addPhoneNumber.pending);
        return { ...state, addingPhoneNumber: true, phoneNumberError: '' };
      }
    );
    builder.addCase(
      addPhoneNumber.fulfilled,
      (state: State, action): State => {
        debug(addPhoneNumber.fulfilled, {
          payload: action.payload
        });

        // Phone number not changed
        if (!action.payload?.data?.phoneNumber) {
          return {
            ...state,
            addingPhoneNumber: false
          };
        }

        return {
          ...state,
          addingPhoneNumber: false,
          user: {
            ...state.user,
            phone: action.payload.data.phoneNumber
          }
        };
      }
    );
    builder.addCase(
      addPhoneNumber.rejected,
      (state: State, action): State => {
        debug(addPhoneNumber.rejected, {
          payload: action.payload.validationError
        });
        return {
          ...state,
          addingPhoneNumber: false,
          phoneNumberError: action.payload.validationError
        };
      }
    );
    builder.addCase(
      requestSuperUser.pending,
      (state: State): State => {
        debug(requestSuperUser.pending);
        return {
          ...state,
          superUserError: ''
        };
      }
    );
    builder.addCase(
      requestSuperUser.rejected,
      (state: State, action): State => {
        const { meta } = action.payload;
        const error = meta.suData || meta.suType || action.payload.message;

        debug(requestSuperUser.rejected, {
          payload: action.payload
        });
        return {
          ...state,
          superUserError: error
        };
      }
    );
    builder.addCase(
      login.pending,
      (state: State): State => {
        debug(login.pending);
        return { ...state, loggingIn: true };
      }
    );
    builder.addCase(
      login.fulfilled,
      (state: State, action): State => {
        debug(login.fulfilled, {
          userFromAction: getUserFromAction(action),
          action
        });
        return {
          ...state,
          loggingIn: false,
          user: getUserFromAction(action),
          loaded: true,
          loginSuccess: true
        };
      }
    );
    builder.addCase(
      SUBMIT_DETAILS_SUCCESS,
      (state: State, action): State => ({
        ...state,
        user: getUserFromAction({
          payload: {
            data: action.payload
          }
        })
      })
    );
    builder.addCase(
      resetPassword.pending,
      (state: State): State => {
        debug(resetPassword.pending);
        return {
          ...state
        };
      }
    );
    builder.addCase(
      resetPassword.rejected,
      (state: State, action: any): State => {
        debug(resetPassword.rejected);
        if (!action.payload.data) {
          return {
            ...state
          };
        }

        const displayEmail = action.payload.data.code === 10001;

        return {
          ...state,
          resetPasswordMessage: displayEmail ? 'EMAIL' : 'PHONE'
        };
      }
    );
    builder.addCase(
      resetPassword.fulfilled,
      (state: State, action: any): State => {
        debug(resetPassword.fulfilled);
        const usedEmail = action.payload.data.type === 'email';
        return {
          ...state,
          resetPasswordMessage: usedEmail ? 'EMAIL' : 'PHONE'
        };
      }
    );
    builder.addCase(
      VALIDATE_PASSWORD,
      (state: State, action: any): State => {
        debug(VALIDATE_PASSWORD);
        return {
          ...state,
          passwordError: action.payload
        };
      }
    );
    builder.addCase(
      resetPasswordConfirmation.pending,
      (state: State): State => {
        debug(resetPasswordConfirmation.pending);
        return {
          ...state,
          setNewPasswordLoading: true,
          passwordError: '',
          setNewPasswordApiError: ''
        };
      }
    );
    builder.addCase(
      resetPasswordConfirmation.fulfilled,
      (state: State): State => {
        debug(resetPasswordConfirmation.fulfilled);
        return {
          ...state,
          passwordUpdated: true,
          setNewPasswordLoading: false,
          resetPasswordLinkExpired: false
        };
      }
    );
    builder.addCase(
      resetPasswordConfirmation.rejected,
      (state: State, action: any): State => {
        debug(resetPasswordConfirmation.rejected, action);
        const { message, error, data } = action.payload;
        const apiErrorMessage = data?.code === 100015 ? data.title : message;

        return {
          ...state,
          resetPasswordLinkExpired: error === 16001,
          setNewPasswordApiError:
            apiErrorMessage || 'Sorry there has been an error',
          setNewPasswordLoading: false
        };
      }
    );
    builder.addCase(
      loginGoogleAsyncThunk.pending,
      (state: State): State => {
        debug(loginGoogleAsyncThunk.pending);
        return {
          ...state,
          googleLoading: true
        };
      }
    );
    builder.addCase(
      loginGoogleAsyncThunk.fulfilled,
      (state: State, action): State => {
        debug(loginGoogleAsyncThunk.fulfilled, action);
        return {
          ...state,
          loggingIn: false,
          user: getUserFromAction(action),
          loaded: true,
          googleLoading: false,
          loginSuccess: true
        };
      }
    );
    builder.addCase(
      loginGoogleAsyncThunk.rejected,
      (state: State, action): State => {
        debug(loginGoogleAsyncThunk.rejected, action);

        if (action.payload && action.payload.message) {
          return {
            ...state,
            loggingIn: false,
            googleLoading: false,
            apiError: {
              title: 'Something went wrong with Google Sign In',
              info: action.payload.message,
              code: action.payload.code
            },
            loginSuccess: false
          };
        }
        return {
          ...state,
          loggingIn: false,
          googleLoading: false,
          apiError: {
            title: 'Something went wrong with Google Sign In'
          },
          loginSuccess: false
        };
      }
    );
    builder.addCase(
      loginFacebookAsyncThunk.pending,
      (state: State): State => {
        debug(loginFacebookAsyncThunk.pending);
        return {
          ...state,
          facebookLoading: true
        };
      }
    );
    builder.addCase(
      loginFacebookAsyncThunk.fulfilled,
      (state: State, action): State => {
        debug(loginFacebookAsyncThunk.fulfilled, action);
        return {
          ...state,
          loggingIn: false,
          user: getUserFromAction(action),
          loaded: true,
          facebookLoading: false,
          loginSuccess: true
        };
      }
    );
    builder.addCase(
      loginFacebookAsyncThunk.rejected,
      (state: State, action): State => {
        debug(loginFacebookAsyncThunk.rejected, action);

        if (action.payload && action.payload.message) {
          return {
            ...state,
            loggingIn: false,
            facebookLoading: false,
            apiError: {
              title: 'Something went wrong with Facebook Sign In',
              info: action.payload.message,
              code: action.payload.code
            },
            loginSuccess: false
          };
        }
        return {
          ...state,
          loggingIn: false,
          facebookLoading: false,
          apiError: {
            title: 'Something went wrong with Facebook Sign In'
          },
          loginSuccess: false
        };
      }
    );
    builder.addCase(
      loginAppleAsyncThunk.rejected,
      (state: State, action): State => {
        debug(loginAppleAsyncThunk.rejected, action);

        if (action.payload && action.payload.message) {
          return {
            ...state,
            loggingIn: false,
            apiError: {
              title: 'Something went wrong with Apple Sign In',
              info: action.payload.message,
              code: action.payload.code
            },
            loginSuccess: false
          };
        }
        return {
          ...state,
          loggingIn: false,
          apiError: {
            title: 'Something went wrong with Apple Sign In'
          },
          loginSuccess: false
        };
      }
    );
    builder.addCase(
      loginAppleAsyncThunk.fulfilled,
      (state: State, action): State => {
        debug(loginAppleAsyncThunk.fulfilled, action);

        return {
          ...state,
          loggingIn: false,
          user: getUserFromAction(action),
          loaded: true,
          loginSuccess: true
        };
      }
    );
    builder.addCase(
      register.pending,
      (state: State): State => {
        debug(register.pending);
        return {
          ...state,
          loggingIn: true
        };
      }
    );
    builder.addCase(
      register.fulfilled,
      (state: State, action): State => {
        debug(register.fulfilled, getUserFromAction(action));
        return {
          ...state,
          loggingIn: false,
          user: getUserFromAction(action),
          loaded: true,
          newUser: true,
          loginSuccess: true,
          newUserSuccess: true
        };
      }
    );
    builder.addCase(
      register.rejected,
      (state: State, action): State => {
        debug(register.rejected, action);

        return {
          ...state,
          loggingIn: false,
          user: null,
          registerError: action.payload.message,
          registerErrorCode: action.payload.code,
          registration: { ...state.registration, showingErrors: true },
          loginSuccess: false,
          newUserSuccess: false
        };
      }
    );
    builder.addCase(
      login.rejected,
      (state: State, action): State => {
        debug(login.rejected, action.payload);
        return {
          ...state,
          loggingIn: false,
          user: null,
          loginError: action.payload,
          loginSuccess: false
        };
      }
    );
    builder.addCase(
      UPDATE_REG_FORM,
      (state: State, action): State => {
        debug(UPDATE_REG_FORM, action);
        const { registration } = state;

        const newRegistration = {
          isDirty: true,
          ...registration,
          ...action.update
        };
        const errors = getErrors(newRegistration);
        return {
          ...state,
          registration: {
            ...newRegistration,
            registerValid: validRegister(errors),
            ...errors
          }
        };
      }
    );
    builder.addCase(
      logout.pending,
      (state: State): State => {
        debug(logout.pending);
        return { ...state, loggingOut: true };
      }
    );
    builder.addCase(
      logout.fulfilled,
      (): State => {
        debug(logout.fulfilled);
        return initialState;
      }
    );
    builder.addCase(
      CLEAR_API_ERROR,
      (state: State): State => {
        debug(CLEAR_API_ERROR);
        return {
          ...state,
          apiError: null
        };
      }
    );
    builder.addCase(
      CLEAR_REG_FORM,
      (state: State): State => {
        debug(CLEAR_REG_FORM);
        return {
          ...state,
          registration: initialState.registration
        };
      }
    );
    builder.addCase(
      deleteAccount.fulfilled,
      (): State => ({
        ...initialState
      })
    );
  }
});

export const {
  clearPhoneNumberError,
  clearAuth,
  setLastBookingWasInsured
} = authSlice.actions;
type GlobalState = { auth: State };

export function hasPurchases(globalState: GlobalState) {
  return (
    globalState.auth &&
    globalState.auth.user &&
    globalState.auth.user.hasSeasonTicket
  );
}

export const setNewPassword = (
  email: string,
  key: string,
  newPassword: string,
  confirmPassword: string
) => (dispatch: (a: Action) => void, getState: () => GlobalState, { t }) => {
  const error = validateResetPassword(newPassword, confirmPassword, t);
  if (error) {
    return dispatch({ type: VALIDATE_PASSWORD, payload: error });
  }

  return dispatch(
    resetPasswordConfirmation({ email, key, newPassword, confirmPassword })
  );
};

export function googleLogin(
  queryParams: any,
  suppressVerificationEmail: boolean
) {
  return (dispatch: (a: Action) => Promise<{ access_token: string }>) => {
    actionsGoogleLogin().then(({ access_token }) =>
      dispatch(
        loginGoogleAsyncThunk({
          access_token,
          queryParams,
          suppressVerificationEmail
        })
      )
    );
  };
}

export function facebookLogin(
  queryParams: any,
  suppressVerificationEmail: boolean
) {
  return (dispatch: (a: Action) => Promise<{ accessToken: string }>) => {
    dispatch(facebookActions.login()).then((response) => {
      if (!response) {
        // eslint-disable-next-line no-console
        console.warn('No response from FB Login');
        return;
      }

      const { accessToken } = response;

      dispatch(
        loginFacebookAsyncThunk({
          accessToken,
          queryParams,
          suppressVerificationEmail
        })
      );
    });
  };
}

export const updateRegForm = makeActionCreator(UPDATE_REG_FORM, 'update');
export const clearApiError = makeActionCreator(CLEAR_API_ERROR);
export const clearRegistrationForm = makeActionCreator(CLEAR_REG_FORM);

const successTrackingEvent = (user) =>
  user.newUser
    ? 'action:sign-up:signup-successful'
    : 'action:log-in:successful';

const amplitudeTrack = (user, method) => {
  if (user.newUser) {
    ampli.registrationCompleted({
      auth_method: method
    });
  } else {
    ampli.loginCompleted({
      auth_method: method
    });
  }
};

export const analyticsEventSubscribers = {
  [login.fulfilled]: (action: Action, analytics: any) => {
    analytics.track('action:log-in:successful', { method: 'EMAIL' });
    ampli.loginCompleted({
      auth_method: 'email'
    });
  },
  [login.rejected]: (action: Action, analytics: any) => {
    analytics.track('action:log-in:failed', { method: 'EMAIL' });
  },
  [register.fulfilled]: (action: Action, analytics: any) => {
    analytics.track('action:sign-up:signup-successful', { method: 'EMAIL' });
    ampli.registrationCompleted({
      auth_method: 'email'
    });
  },
  [register.rejected]: (action: Action, analytics: any) => {
    analytics.track('action:sign-up:sign-up-failed', { method: 'EMAIL' });
  },
  [loginGoogleAsyncThunk.fulfilled]: (action: Action, analytics: any) => {
    const user = getUserFromAction(action);
    analytics.track(successTrackingEvent(user), { method: 'GOOGLE' });
    amplitudeTrack(user, 'google');
  },
  [loginGoogleAsyncThunk.rejected]: (action: Action, analytics: any) => {
    analytics.track('action:log-in:failed', { method: 'GOOGLE' });
  },
  [loginFacebookAsyncThunk.fulfilled]: (action: Action, analytics: any) => {
    const user = getUserFromAction(action);
    analytics.track(successTrackingEvent(user), { method: 'FACEBOOK' });
    amplitudeTrack(user, 'facebook');
  },
  [loginFacebookAsyncThunk.rejected]: (action: Action, analytics: any) => {
    analytics.track('action:log-in:failed', { method: 'FACEBOOK' });
  },
  [loginAppleAsyncThunk.fulfilled]: (action: Action, analytics: any) => {
    const user = getUserFromAction(action);
    analytics.track(successTrackingEvent(user), { method: 'APPLE' });
    amplitudeTrack(user, 'apple');
  }
};

export const selectAuthGoogleLoading = (state: { auth: State }) =>
  state.auth.googleLoading;
export const selectAuthFacebookLoading = (state: { auth: State }) =>
  state.auth.facebookLoading;
export const selectResetPasswordPasswordError = (state: { auth: State }) =>
  state.auth.passwordError;
export const selectResetPasswordPasswordUpdateSuccess = (state: {
  auth: State;
}) => state.auth.passwordUpdated;
export const selectResetPasswordApiError = (state: { auth: State }) =>
  state.auth.setNewPasswordApiError;
export const selectResetPasswordLoading = (state: { auth: State }) =>
  state.auth.setNewPasswordLoading;
export const selectResetPasswordLinkExpired = (state: { auth: State }) =>
  state.auth.resetPasswordLinkExpired;

export const selectUser = (state: { auth: State }) => state.auth.user;

// TODO: Update this when a user adds a listing vis the SPA
export const selectAuthIsSpaceOwner = (state: { auth: State }) =>
  state.auth?.user?.isSpaceOwner;

export const selectAuthisActiveSpaceOwner = (state: { auth: State }) =>
  state.auth?.user?.isActiveSpaceOwner;

export const selectUserLastBookingInsured = (state: { auth: State }) =>
  state.auth?.user?.lastBookingWasInsured;

// TODO: Update this number when a user makes a booking via the SPA
export const selectAuthIsActiveDriver = (state: { auth: State }) =>
  state.auth?.user?.bookingSummary?.totalBookingsMade > 0;

export const selectAuthHasSeasonTicket = (state: { auth: State }) =>
  state.auth?.user?.hasSeasonTicket;

export const selectResetPasswordMessage = (state: { auth: State }) =>
  state.auth.resetPasswordMessage;

export const selectRegistrationFormFirstName = (state: { auth: State }) =>
  state.auth.registration.firstName;

export const selectRegistrationFormLastName = (state: { auth: State }) =>
  state.auth.registration.lastName;

export const selectRegistrationFormEmail = (state: { auth: State }) =>
  state.auth.registration.email;

export const selectRegistrationFormPassword = (state: { auth: State }) =>
  state.auth.registration.password;

export const selectRegistrationFormConsents = (state: { auth: State }) =>
  state.auth.registration.consents;

export const selectUserEmail = (state: { auth: State }) =>
  state.auth.user.email;

export const selectIsApplePrivateEmail = createSelector(
  selectUserEmail,
  (email) => email.includes('privaterelay.appleid.com')
);

export const selectUserId = (state: { auth: State }) => state.auth.user?.id;

export const selectUserDateOfBirth = (state: { auth: State }) =>
  state.auth.user?.dateOfBirth;

export const selectUserHasDateOfBirth = createSelector(
  selectUserDateOfBirth,
  (dateOfBirth) => !!dateOfBirth
);

export const selectUserPhone = (state: { auth: State }) =>
  state.auth.user?.phone || '';

export const selectUserFirstName = (state: { auth: State }) =>
  state.auth.user?.firstName || '';

export const selectUserAddingPhoneNumber = (state: { auth: State }) =>
  state.auth.addingPhoneNumber;

export const selectUserPhoneNumberError = (state: { auth: State }) =>
  state.auth.phoneNumberError;

export const selectNoUserOrUserPhone = createSelector(
  selectUser,
  selectUserPhone,
  (user, userPhone) => !user || !userPhone
);

export const selectIsBookingsMadeMoreThanFive = (state: { auth: State }) =>
  state.auth.user?.bookingsMade >= 5;

export const selectIsAdmin = (state: { auth: State }) =>
  state.auth.user?.isAdmin;
export const selectIsSuperuser = (state: { auth: State }) =>
  state.auth.user?.isSuperuser;
export const selectIsAdminOrIsSuperuser = createSelector(
  selectIsAdmin,
  selectIsSuperuser,
  (isAdmin, isSuperuser) => isAdmin || isSuperuser
);

export const selectUserHasVerifiedEmail = (state: { auth: State }) =>
  state.auth.user?.hasVerifiedEmail;
export const selectRegistrationErrorCode = (state: { auth: State }) =>
  state.auth.registerErrorCode;
export const selectDisplayTryLoginMessage = createSelector(
  selectRegistrationErrorCode,
  (errorCode) => errorCode === 100007
);
export const selectUserHasVerifiedPhone = (state: { auth: State }) =>
  state.auth.user?.hasVerifiedPhoneNumber;

export const selectSuperUserMessage = (state: { auth: State }) =>
  state.auth.superUserError;
export const selectUserIsLoggingIn = (state: { auth: State }) =>
  state.auth.loggingIn;

export const selectAuthIsLoaded = (state) => state.auth && state.auth.loaded;

export default authSlice;
