import trackingLongSession from '@justpark/api/src/requests/trackingLongSession';
import userTracking from '@justpark/api/src/requests/userTracking';
import { getCookieValue, getQueryParam } from './utils';

const userTrackingRequest = (jpApiClient) => {
  const request = userTracking({
    referrer: document.referrer,
    requestUri: window.location.href,
    source: getQueryParam(window.location.href, 'source')
  });

  jpApiClient(request).catch(console.error);

  // Store an initial session in the local storage if one doesn't exist already
  // This is used for referral tracking
  const jpTracking = getCookieValue('jp_session');
  if (!jpTracking) {
    let affiliateId = getQueryParam(window.location.href, 'aff');
    affiliateId = affiliateId ? affiliateId.replace('aff', '') : null;
    const inviteCode = getQueryParam(window.location.href, 'invite_code');

    const sessionRequest = trackingLongSession({
      referrer: document.referrer,
      requestUri: window.location.href,
      affiliateId: Number.isNaN(affiliateId) ? null : affiliateId,
      inviteCode
    });

    jpApiClient(sessionRequest).catch(console.error);
  }
};

export default userTrackingRequest;
