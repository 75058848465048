import React from 'react';
import { NavLink } from '@not-remix-run/react';
import Typography from '@justpark/ui/src/components/Typography';

import '../../../static/scss/components/ui/linkText.scss';

type Props = {
  to: string;
  children: any;
  size?: string;
  cypress?: string;
  underline?: boolean;
};

const LinkText = ({
  to,
  children,
  size = 'secondary',
  cypress,
  underline = false
}: Props) => (
  <Typography
    decoration={underline ? 'underline' : ''}
    size={size}
    className="c-link-text"
  >
    <NavLink to={to} data-cy={cypress}>
      {children}
    </NavLink>
  </Typography>
);

export default LinkText;
