// @ts-nocheck
import React from "react";
import CollapsibleContainer from "../CollapsibleContainer";
import LinkButton from "../LinkButton";
import Typography from "../Typography";
import ChevronIcon from "../Icons/ChevronIcon";
import styles from "./collapsibleColumnAccordionItem.module.scss";

type Props = {
  title: string;
  children: any;
  onClick?: () => void;
  isOpen?: boolean;
};
const CollapsibleColumnAccordionItem = ({
  title,
  children,
  onClick,
  isOpen
}: Props) => {
  return (
    <div className={styles.item} data-cy="collapsible-column-accordion-item">
      <LinkButton onClick={onClick} className={styles.title}>
        <Typography bold size="h2" className={styles.titleTypography}>
          {title}
        </Typography>
        <div className={styles.accordionChevron}>
          <ChevronIcon
            direction={isOpen ? "up" : "down"}
            className={styles.accordionChevronSvg}
          />
        </div>
      </LinkButton>
      <CollapsibleContainer
        className={styles.collapsibleContainer}
        isOpened={isOpen}
      >
        <div className={styles.childContainer}>{children}</div>
      </CollapsibleContainer>
    </div>
  );
};
export default CollapsibleColumnAccordionItem;
