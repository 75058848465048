import type { AxiosPromise, AxiosInstance } from "axios";
import debug from "../helpers/debug";

const getPartnerWhiteLabel = (
  partnerName: string
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  debug("getPartnerWhiteLabel", partnerName);

  return apiInstance.get(`/v5/whitelabel/${partnerName}`);
};

export default getPartnerWhiteLabel;
