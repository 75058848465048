// @ts-nocheck
import React, { useMemo, useState, useCallback } from "react";
import styles from "./collapsibleColumnAccordion.module.scss";

type Props = {
  children: any;
  className?: string | null;
};
const CollapsibleColumnAccordion = ({ children, className }: Props) => {
  const [openIndex, setOpenIndex] = useState(null);
  const onClickCallback = useCallback((index) => {
    setOpenIndex((existingValue) => {
      if (index === existingValue) {
        return null;
      }
      return index;
    });
  }, []);
  const mappedChildren = useMemo(
    () =>
      React.Children.map(children, (element, index) => {
        return React.cloneElement(element, {
          onClick: () => {
            onClickCallback(index);
          },
          isOpen: openIndex === index
        });
      }),
    [children, onClickCallback, openIndex]
  );
  return (
    <div className={`${styles.wrapper} ${className}`}>{mappedChildren}</div>
  );
};
export default CollapsibleColumnAccordion;
