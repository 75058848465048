import type { AxiosInstance, AxiosPromise } from "axios";
import type { UserTransformer } from "../types";

const login = (
  email: string,
  password: string,
  recaptchaToken: string,
  params?: any | null,
  include?: string
): ((apiInstance: AxiosInstance) => AxiosPromise<UserTransformer>) => (
  apiInstance
) => {
  return apiInstance.post("/v5/auth/login", {
    username: email,
    password,
    g_recaptcha_response: recaptchaToken,
    params,
    include
  });
};

export default login;
