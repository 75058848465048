/* eslint-disable no-bitwise, global-require */
import dbg from 'debug';

const debug = dbg('jp:analytics');

let instance = null;

export default class Analytics {
  constructor() {
    if (instance) {
      return instance;
    }

    this.defaultProperties = {};
    this.tracking = null;

    if (
      !instance &&
      import.meta.env.SSR === false &&
      process.env.NODE_ENV !== 'test'
    ) {
      instance = this;
      return instance;
    }

    return {
      page: () => {},
      identify: () => {},
      track: () => {},
      reset: () => {},
      setDefaultProperties: () => {}
    };
  }

  setDefaultProperties(properties = {}) {
    debug('defaultProperties', { properties });

    this.defaultProperties = {
      ...this.defaultProperties,
      ...properties
    };

    if ('dataLayer' in window) {
      window.dataLayer.push({
        defaultProperties: this.defaultProperties
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  identify(userId = '', traits = {}, options = {}, callback = () => {}) {
    callback();
    console.warn('Analytics identify is deprecated');
  }

  track(event, properties = {}, options = {}, callback = () => {}) {
    const props = {
      ...properties,
      ...this.defaultProperties,
      date: ~~(Date.now() / 1000)
    };

    debug('event', { event, props, options });
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event,
        eventType: 'customJPEvent',
        eventData: properties,
        _clear: true
      });
    }

    callback();
  }

  // eslint-disable-next-line class-methods-use-this
  alias() {
    console.warn('Analytics alias is deprecated');
  }

  page(name = '', properties = {}, options = {}) {
    const props = {
      ...properties,
      ...this.defaultProperties,
      date: ~~(Date.now() / 1000)
    };

    debug('page', { name, props, options });

    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: `page:${name}`,
        eventType: 'page',
        eventData: properties
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  reset() {
    console.warn('Analytics reset is deprecated');
  }
}

// Analytics singleton
const analytics = new Analytics();

export function track(
  event,
  properties = {},
  options = {},
  callback = () => {}
) {
  setTimeout(() => {
    analytics.track(event, properties, options, callback);
  }, 0);
}

export function page(name, properties = {}, options = {}) {
  setTimeout(() => {
    analytics.page(name, properties, options);
  }, 0);
}

export function gtag(args) {
  if (import.meta.env.SSR === false && 'dataLayer' in window) {
    window.dataLayer.push({
      ...args
    });
  }
}

/**
 * Track function that just pushed to the data layer
 * There is a problem with out track function in the Analytics class
 * Because it uses a promise, the racking code is not called immediately even if the promise is resolved
 * Instead it's called during the next tick. So tracking calls can be called late, which s an issue if we are calling track before a page change
 * as by the time track has been called the URL is different to the page that it was called on
 */
export function gtagTrack(event, args = {}) {
  // https://www.simoahava.com/analytics/two-simple-data-model-tricks/
  // Push clear to prevent recursive merging of the items arrays and eventData object
  gtag({ event, eventData: { ...args }, _clear: true });
}
