import config from '../../config/config';

const { apiProtocol, apiHost, apiPort, isDevelopment } = config;

const shouldAlterHost = isDevelopment;

export function host() {
  const port =
    shouldAlterHost && apiPort.length && apiPort !== '443' && apiPort !== '80'
      ? `:${apiPort}`
      : '';
  return shouldAlterHost ? `${apiProtocol}://${apiHost}${port}` : '';
}

export default host;
