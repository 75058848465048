import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { useSelector, useDispatch } from 'react-redux';
import parseCookieString from '@justpark/helpers/src/parseCookieString/parseCookieString';
import { show as showSnackbar } from '@justpark/snackbar/src/reducers/snackbar';
import { selectUserId } from '../../stores/auth';
import { applyPromotion, selectPromotionsState } from '../../stores/promotions';
import { ampli } from '../../helpers/Tracking/amplitude';

const PromoModal = loadable(
  () => import(/* webpackChunkName: "promo-modal" */ './PromoModal')
);

const cookieName = 'jp_promo_code';

const LazyPromoModal = () => {
  const userId = useSelector(selectUserId);
  const promotionResponse = useSelector(selectPromotionsState);
  const dispatch = useDispatch();

  useEffect(() => {
    const cookies = parseCookieString(document.cookie);
    const hasCookie = cookieName in cookies;

    if (!hasCookie) {
      return;
    }

    if (!userId) {
      dispatch(
        showSnackbar('Promo code will be applied when you login or register')
      );
      return;
    }

    dispatch(applyPromotion());
  }, [userId, dispatch]);

  const showPromoModal = promotionResponse !== '';
  const promoSuccess = promotionResponse === 'success';
  const promoErrorCode = promoSuccess ? null : promotionResponse;

  useEffect(() => {
    if (promoSuccess) {
      ampli.promotionApplied();
    }
  }, [promoSuccess]);

  return (
    showPromoModal && (
      <PromoModal promoSuccess={promoSuccess} errorCode={promoErrorCode} />
    )
  );
};

export default LazyPromoModal;
