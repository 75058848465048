import { createListenerMiddleware } from '@reduxjs/toolkit';

const appInterfaceMiddleware = createListenerMiddleware();

const actionMap = [
  ['VEHICLE_ADDED', 'vehicle/createUserVehicle/fulfilled'],
  ['VEHICLE_DELETED', 'vehicle/deleteUserVehicle/fulfilled'],
  ['PAYMENT_METHOD_ADDED', 'payments/createPaymentMethod/fulfilled'],
  ['PAYMENT_METHOD_DELETED', 'payments/deletePaymentMethod/fulfilled'],
  ['CHANGE_EMAIL', 'profile/submitEmail/fulfilled'],
  ['CHANGE_DETAILS', 'profile/submitDetails/fulfilled'],
  ['PASSWORD_RESET_REQUEST', 'profile/sendResetPasswordLink/fulfilled'],
  ['CHANGE_ADDRESS', 'profile/submitAddress/fulfilled']
];

actionMap.forEach(([value, type]) =>
  appInterfaceMiddleware.startListening({
    type,
    effect: () => {
      if (
        import.meta.env.SSR === false &&
        'AppInterface' in window &&
        'updateProfile' in window.AppInterface
      ) {
        window.AppInterface.updateProfile(value);
      }
    }
  })
);

export default appInterfaceMiddleware;
