import React from 'react';
import AppStoreIcon from '../../../static/images/appstore_bt.svg';
import GooglePlayIcon from '../../../static/images/googleplay_bt.svg';
import AppStoreIconLight from '../../../static/images/appstore_light_bt.svg';
import GooglePlayIconLight from '../../../static/images/googleplay_light_bt.svg';
import { track } from '../../helpers/Tracking/Analytics';

type Props = {
  baseClassName?: string;
  startStop?: boolean;
  locationTrackEvent: string;
  theme?: 'light' | 'dark';
};

const AppIcons = ({
  baseClassName = 'c-appIcons',
  startStop = false,
  locationTrackEvent,
  theme = 'dark'
}: Props) => {
  const className = baseClassName;

  const appStore = theme === 'dark' ? AppStoreIcon : AppStoreIconLight;
  const googlePlay = theme === 'dark' ? GooglePlayIcon : GooglePlayIconLight;

  return (
    <ul className={className}>
      <li>
        <a
          onClick={() =>
            startStop
              ? track('temp:search-results:clicked-appstore', {
                  appstore_type: 'Apple',
                  location: locationTrackEvent
                })
              : track('click:download-app:ios', {
                  location: locationTrackEvent
                })
          }
          target="_blank"
          rel="noopener noreferrer"
          href="https://justpark.onelink.me/eUfx/448422cd"
        >
          <img
            src={appStore}
            alt="AppStore"
            loading="lazy"
            width="135"
            height="40"
          />
        </a>
      </li>
      <li>
        <a
          onClick={() =>
            startStop
              ? track('temp:search-results:clicked-appstore', {
                  appstore_type: 'Google',
                  location: locationTrackEvent
                })
              : track('click:download-app:android', {
                  location: locationTrackEvent
                })
          }
          target="_blank"
          rel="noopener noreferrer"
          href="https://justpark.onelink.me/n8HU/464fc314"
        >
          <img
            src={googlePlay}
            alt="Google Play"
            loading="lazy"
            width="300"
            height="89"
          />
        </a>
      </li>
    </ul>
  );
};

export default AppIcons;
