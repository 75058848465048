import type { AxiosPromise, AxiosInstance } from "axios";

const evFleetsFinishSignup = (
  selectedPaymentMethodId: number
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v5/fleets/finish-signup", {
    selected_card_id: selectedPaymentMethodId
  });
};

export default evFleetsFinishSignup;
