import { useState, useRef, useEffect } from 'react';

const useLazyLoadIntersection = (offset) => {
  const [triggered, setTriggered] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (triggered) {
      return () => {};
    }

    if (!('IntersectionObserver' in window)) {
      setTriggered(true);
      return () => {};
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        setTriggered(isIntersecting);
      },
      {
        threshold: 0.1,
        rootMargin: `${offset}px`
      }
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref, offset, triggered]);

  return [ref, triggered];
};

export default useLazyLoadIntersection;
