import type { AxiosPromise, AxiosInstance } from "axios";

const superUserRequest = (
  type: string,
  data: string
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v5/admin/su", {
    su_type: type,
    su_data: data
  });
};

export default superUserRequest;
