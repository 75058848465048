import type { Ampli } from '../../generated/ampli';
import config from '../../../config/config';
import { selectIsWebview } from '../../stores/ui';
import { waitForCookiePolicy } from '../cookiePolicy';
import { selectOnetrust } from '../../stores/launchDarkly';

const handler = () => {
  if (import.meta.env.SSR) {
    return {
      get() {
        return () => {
          // Amplitude tracking is a noop during server side rendering
        };
      }
    };
  }

  const ampliImport = import('../../generated/ampli/index');

  let resolveLoad;
  const loadPromise = new Promise((resolve) => {
    resolveLoad = resolve;
  });

  return {
    get(target, prop) {
      if (prop === 'client') {
        return {
          add: (...args) =>
            ampliImport.then((ampliModule) =>
              ampliModule.ampli.amplitude.add(...args)
            )
        };
      }

      if (prop === 'setSessionId') {
        return (...args) =>
          ampliImport.then((ampliModule) =>
            ampliModule.ampli.amplitude.setSessionId(...args)
          );
      }

      return (...args) =>
        ampliImport.then(({ ampli }) => {
          if (prop === 'load') {
            const result = ampli[prop](...args);
            resolveLoad();

            return result;
          }

          return loadPromise.then(() => ampli[prop](...args));
        });
    }
  };
};

export const ampli: Ampli = new Proxy({}, handler());

class PropertiesEnrichmentPlugin {
  name = 'properties-plugin';

  type = 'enrichment';

  constructor(state) {
    this.state = state;
    this.isMobile = false;
  }

  async setup() {
    const userId = this.state?.auth?.user?.id;
    const properties: string[] = [];

    if (this.state.launchDarkly.ids.length > 0) {
      this.state.launchDarkly.ids.forEach((id) => {
        const feature = this.state.launchDarkly.entities[id];
        if (typeof feature.value !== 'object') {
          properties.push(`${id}.${feature.value}`);
        } else {
          properties.push(`${id}.${feature.variationIndex}`);
        }
      });
    }

    ampli.identify(userId, {
      launch_darkly: properties
    });

    this.isMobile = window.innerWidth <= 640;

    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  async execute(event) {
    // eslint-disable-next-line no-param-reassign
    event.event_properties = event.event_properties || {};

    // eslint-disable-next-line no-param-reassign
    event.event_properties.mobile_web = this.isMobile;

    return event;
  }
}

const ampliInit = (reduxState, sessionId) => {
  const isWebview = selectIsWebview(reduxState);
  const onetrust = selectOnetrust(reduxState);

  const cookieInitOrSkip = () => {
    if (isWebview || !onetrust) {
      return Promise.resolve(true);
    }

    return waitForCookiePolicy().then(() => {
      const groupsArray = window?.OptanonActiveGroups?.split(',');
      const performanceCookiesGroup = 'C0002';
      return groupsArray?.indexOf(performanceCookiesGroup) > -1;
    });
  };

  cookieInitOrSkip().then((optIn) => {
    if (optIn) {
      ampli.load({
        environment: config.isProduction ? 'production' : 'staging',
        client: {
          configuration: {
            defaultTracking: {
              formInteractions: false,
              fileDownloads: false,
              sessions: !sessionId
            }
          }
        }
      });

      if (sessionId) {
        ampli.setSessionId(sessionId);
      }

      ampli.client.add(new PropertiesEnrichmentPlugin(reduxState));
    }
  });
};

export default ampliInit;
