import React from 'react';
import { useTranslation } from 'react-i18next';
import LegacyLink from '../../Common/LegacyLink';

type Props = {
  styles: {
    list: string;
    navigationLinks: string;
  };
};

const PointsOfInterestLinks = ({ styles }: Props) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      <li>
        <LegacyLink to="uk/airport-parking/" className={styles.navigationLinks}>
          {t('footer:airportParking', 'Airport parking')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink
          to="destinations/city-breaks"
          className={styles.navigationLinks}
        >
          {t('footer:cityParking', 'City parking')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink to="uk/stadium-parking/" className={styles.navigationLinks}>
          {t('footer:stadiumParking', 'Stadium parking')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink
          to="uk/national-rail-parking/"
          className={styles.navigationLinks}
        >
          {t('footer:stationParking', 'Station parking')}
        </LegacyLink>
      </li>
    </ul>
  );
};

export default PointsOfInterestLinks;
