import type { AxiosPromise, AxiosInstance } from "axios";

type Args = {
  referrer: string;
  requestUri: string;
  source: string;
};

const userTracking = ({
  referrer,
  requestUri,
  source
}: Args): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v5/users/tracking", {
    referrer,
    request_uri: requestUri,
    source
  });
};

export default userTracking;
