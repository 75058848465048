import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from '@not-remix-run/react';
import CollapsibleColumnAccordion from '@justpark/ui/src/components/CollapsibleColumnAccordion';
import CollapsibleColumnAccordionItem from '@justpark/ui/src/components/CollapsibleColumnAccordionItem';
import Typography from '@justpark/ui/src/components/Typography';
import JustParkLogo from '@justpark/ui/src/components/JustParkLogo';
import AppIcons from '../../Common/AppIcons';
import InformationLinks from './InformationLinks';
import PointsOfInterestLinks from './PointsOfInterestLinks';
import GetInTouchLinks from './GetInTouchLinks';
import ServicesLinks from './ServicesLinks';
import SocialLinks from './SocialLinks';
import LocaleDropdown from '../LocaleDropdown';
import LowerNavigationLinks from './LowerNavigationLinks';
import TrustpilotWidget from '../../Widgets/TrustPilotWidget';

import styles from '../../../../static/scss/components/footer.module.scss';

const linkStyles = {
  list: styles.linkList,
  navigationLinks: styles.navigationLinks
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer} data-cy="app-footer">
      <div className={styles.footerContent}>
        <CollapsibleColumnAccordion className={styles.accordionContainer}>
          <CollapsibleColumnAccordionItem
            title={t('footer:titleInformation', 'Information')}
          >
            <InformationLinks styles={linkStyles} />
          </CollapsibleColumnAccordionItem>
          <CollapsibleColumnAccordionItem
            title={t('footer:titleServices', 'Services')}
          >
            <ServicesLinks styles={linkStyles} />
          </CollapsibleColumnAccordionItem>
          <CollapsibleColumnAccordionItem
            title={t('footer:titlePoI', 'Points of interest')}
          >
            <PointsOfInterestLinks styles={linkStyles} />
          </CollapsibleColumnAccordionItem>
          <CollapsibleColumnAccordionItem
            title={t('footer:titleGetInTouch', 'Get in touch')}
          >
            <GetInTouchLinks styles={linkStyles} />
          </CollapsibleColumnAccordionItem>
        </CollapsibleColumnAccordion>

        <NavLink to="/" className={styles.jpLogoContainer}>
          <JustParkLogo className={styles.jpLogo} />
        </NavLink>

        <div className={styles.trustPilotContainer}>
          <TrustpilotWidget theme="dark" height="95px" />
        </div>

        <div className={styles.socialContainer}>
          <Typography
            size="secondary"
            color="primary-inverse"
            className={styles.secondaryHeaders}
            bold
          >
            {t('footer:socialCta', 'Follow us on')}
          </Typography>

          <SocialLinks />
        </div>

        <div className={styles.appDownloadContainer}>
          <Typography
            size="secondary"
            color="primary-inverse"
            className={styles.secondaryHeaders}
            bold
          >
            {t('footer:appDownloadCta', 'Download JustPark App')}
          </Typography>

          <AppIcons
            theme="light"
            locationTrackEvent="home_footer"
            baseClassName={styles.appDownloadList}
          />
        </div>

        <div className={styles.localeContainer}>
          <LocaleDropdown />
        </div>

        <div className={styles.lowerNavContainer}>
          <LowerNavigationLinks />
        </div>

        <div className={styles.legal}>
          {t(
            'footer:legalDisclaimer',
            "JustPark is the trading name of JustPark Parking Limited Registered in England and Wales No. 05956777 Dunn's Hat Factory, 106 - 110 Kentish Town Road, London, NW1 9PX, UK"
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
