import type { AxiosPromise, AxiosInstance } from "axios";

const forgotPasswordV4 = (
  username: string
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v4/auth/password-reset", {
    username
  });
};

export default forgotPasswordV4;
