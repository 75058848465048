import { waitInWindow } from '@justpark/helpers/src/scriptLoader/scriptLoader';
import dbg from 'debug';

const debug = dbg('jp:amplitude');

let waitPromise;

// eslint-disable-next-line import/prefer-default-export
export const waitForCookiePolicy = () => {
  if (!waitPromise) {
    waitPromise = new Promise((resolve) => {
      waitInWindow('OptanonActiveGroups').then(() => {
        if (document.cookie.indexOf('OptanonAlertBoxClosed=') >= 0) {
          // Optanon cookie found, use has either accepted or rejected cookies
          debug('Cookie found, so ready to load analytics');
          resolve();
        }

        function OptanonWrapper() {
          // eslint-disable-next-line no-undef,new-cap
          Optanon.OnConsentChanged(() => {
            debug('Cookie changed, so ready to load analytics');
            resolve();
          });
        }

        window.OptanonWrapper = OptanonWrapper;
      });
    });
  }

  return waitPromise;
};
