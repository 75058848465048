// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Chevron from "../../../svg/chevron.svg";
import "./chevronIcon.scss";

type Props = {
  direction?: "up" | "right" | "down" | "left";
  className?: string;
  width?: number;
  height?: number;
};
const ChevronIcon = ({
  direction = "up",
  className = "",
  width = 24,
  height = 24
}: Props) => (
  <Chevron
    className={`${bem("c-chevron-icon", null, {
      direction
    })} ${className}`}
    style={{
      width: `${width}px`,
      height: `${height}px`
    }}
  />
);

export default ChevronIcon;
