import { useEffect } from 'react';
import dbg from 'debug';
import EventEmitter from 'eventemitter3';

const debug = dbg('jp:oauthTokenReceiver');

export const oauthTokenEmitter = new EventEmitter();

const getEventForProvider = (provider) => {
  if (provider === 'google') {
    return 'google_token';
  }

  return null;
};

const receiveMessage = (event) => {
  // Allow postMessage from other domains during development to allow localhost to listen to staging envs
  if (
    process.env.NODE_ENV !== 'development' &&
    event.origin !== window.location.origin
  ) {
    debug(`Message received by ${event.origin}; IGNORED.`);
    return;
  }

  if (
    typeof event.data === 'object' &&
    'oauth_access_token' in event.data &&
    'oauth_provider' in event.data
  ) {
    oauthTokenEmitter.emit(
      getEventForProvider(event.data.oauth_provider),
      event.data.oauth_access_token
    );
  }
};

const OAuthTokenReceiver = () => {
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  return null;
};

export default OAuthTokenReceiver;
