const eventList = ['keydown', 'mousemove', 'wheel', 'touchmove', 'touchstart', 'touchend'];
function injectGTM(w, d, s, l, i) {
  // eslint-disable-next-line no-param-reassign
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}&gtm_auth=${import.meta.env.GTM_AUTH}&gtm_preview=${import.meta.env.GTM_PREVIEW}&gtm_cookies_win=x`;
  f.parentNode.insertBefore(j, f);
}
function runScripts() {
  injectGTM(window, document, 'script', 'dataLayer', `${import.meta.env.GTM_CONTAINER_ID}`);
}
const autoLoadDuration = 5; // In Seconds
const autoLoadTimeout = setTimeout(runScripts, autoLoadDuration * 1000);
function triggerScripts() {
  runScripts();
  clearTimeout(autoLoadTimeout);
  eventList.forEach(event => {
    window.removeEventListener(event, triggerScripts, {
      passive: true
    });
  });
}
const triggerDelayedScripts = () => {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(triggerScripts, {
      timeout: autoLoadDuration * 1000
    });
    return;
  }
  eventList.forEach(event => {
    window.addEventListener(event, triggerScripts, {
      passive: true
    });
  });
};
export default triggerDelayedScripts;