import React from 'react';
import LegacyLink from '../../Common/LegacyLink';

const tertiaryNavLinks = [
  {
    url: 'how-it-works/overview/',
    text: 'How it works',
    activeKey: 'how-it-works'
  },
  { url: 'about/jobs/', text: 'Jobs', activeKey: 'jobs' },
  {
    url: 'company/newsroom/',
    text: 'Media Coverage',
    activeKey: 'media-coverage'
  },
  { url: 'about/awards/', text: 'Awards', activeKey: 'awards' }
];
type Props = {
  location: any;
};

const TertiaryNav = ({ location }: Props) => (
  <div className="c-header__menu-container--tertiary">
    <ul className="c-header__menu c-header__menu--tertiary">
      {tertiaryNavLinks.map((elem, i) => (
        <li
          key={i}
          className={
            location &&
            location.search &&
            location.search.includes(elem.activeKey)
              ? 'is-active'
              : ''
          }
        >
          <LegacyLink to={elem.url}>
            <span>{elem.text}</span>
          </LegacyLink>
        </li>
      ))}
    </ul>
  </div>
);

export default TertiaryNav;
