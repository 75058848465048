import type { AxiosPromise, AxiosInstance } from "axios";

const deleteAccountConsent = (): ((
  apiInstance: AxiosInstance
) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v5/consents", {
    data: [
      {
        name: "standard",
        accepted: false,
        version: 1
      }
    ]
  });
};

export default deleteAccountConsent;
