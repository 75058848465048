import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from '../../../config/config';
import bugsnagConfig from './config';
import { getCookieValue } from '../utils';
const clientCheck = !config.isTesting && import.meta.env.BUGSNAG_JS_API_KEY;
if (clientCheck) {
  const reactPlugin = new BugsnagPluginReact(React);
  /**
   * Bugsnag configuration variables
   */
  Bugsnag.start({
    ...bugsnagConfig,
    plugins: [reactPlugin],
    appType: 'web',
    beforeSend: event => {
      event.addMetadata('JustPark', {
        api_session_id: getCookieValue('api_session_id')
      });
    }
  });
}
export default Bugsnag;