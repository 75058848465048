import { useNavigate } from '@not-remix-run/react';

// eslint-disable-next-line import/prefer-default-export
export const useLoginLinkClickHandler = () => {
  const navigate = useNavigate();

  return (e) => {
    const { origin, href, pathname } = window.location;

    if (
      pathname.startsWith('/auth/login') ||
      pathname.startsWith('/registration')
    ) {
      return;
    }

    e.preventDefault();
    const next = href.substring(origin.length);

    navigate(`/auth/login/?routerNext=${encodeURIComponent(next)}`);
  };
};
