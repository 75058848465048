// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import "./infoBox.scss";

type Props = {
  children: any;
  type?:
    | "success"
    | "error"
    | "blue"
    | "blue-bright"
    | "green"
    | "transparent"
    | "red"
    | "yellow"
    | "light-yellow";
  className?: string;
  border?: boolean;
  icon?: any;
  cypress?: string | null;
  isScrollable?: boolean;
  slim?: boolean;
};
const baseClass = "c-info-box";
const InfoBox = ({
  type = "success",
  children,
  className = "",
  border = false,
  icon = false,
  cypress = null,
  isScrollable = false,
  slim = false
}: Props) => (
  <div
    data-cy={`info-box-${cypress}`}
    className={`${bem(baseClass, null, {
      type,
      border,
      icon,
      isScrollable,
      slim
    })} ${className}`}
  >
    {icon && <span className={`${baseClass}--icon`}>{icon}</span>}

    {children}
  </div>
);

export default InfoBox;
