import { AxiosError, AxiosResponse } from "axios";

/**
 * Returns the error response from the API if set or and empty object
 */
const getRequestErrorData = (error: AxiosError) => {
  if (error?.response) {
    const response = error.response as AxiosResponse;
    return response?.data;
  }

  return {};
};

export default getRequestErrorData;
