import type { AxiosPromise, AxiosInstance } from "axios";
import debug from "../helpers/debug";

const createPhoneNumber = (
  countryCode: string,
  phoneNumber: string,
  delay: number
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  debug("phoneNumbers.create", countryCode, phoneNumber, delay);
  return apiInstance.post("/v4/users/phone", {
    country_code: countryCode,
    phone_number: phoneNumber,
    delay
  });
};

export default createPhoneNumber;
