import { Loader } from "@googlemaps/js-api-loader";

export const WALKING_METERS_PER_MIN = 80;

/**
 * Loads the Google Maps API
 * @returns {Promise}
 * @resolve {object} google
 */
export const loadGoogle = () => {
  const loader = new Loader({
    apiKey: process.env.GOOGLE_API_KEY,
    libraries: ["places", "geometry"]
  });

  return loader.load();
};

/**
 * Promise wrapper around a Google Maps function
 * @param func
 */
const promisify = (func) => (request) =>
  new Promise((resolve, reject) => {
    func(request, (response, statusCode) => {
      if (statusCode !== "OK") {
        return reject(new Error(statusCode));
      }

      return resolve(response);
    });
  });

/**
 * Get the Google Auto Complete Service
 * https://developers.google.com/maps/documentation/javascript/reference#AutocompleteService
 *
 * @returns {Promise}
 * @resolve {object} AutocompleteService
 */
const getAutocompleteService = () =>
  loadGoogle().then((google) => new google.maps.places.AutocompleteService());

/**
 * Accepts a AutocompletionRequest object and returns an array of AutocompletePredictions
 *
 * @param request {object} https://developers.google.com/maps/documentation/javascript/reference?csw=1#AutocompletionRequest
 * @returns {Promise}
 * @resolve {array} AutocompletePrediction https://developers.google.com/maps/documentation/javascript/reference#AutocompletePrediction
 */
export const getPlacePredictions = (request) =>
  getAutocompleteService().then((autoCompleteService) => {
    const getPlacePredictionsPromise = promisify(
      autoCompleteService.getPlacePredictions.bind(autoCompleteService)
    );

    return getPlacePredictionsPromise(request).then((results) => {
      // For convenience resolve to an array instead of null
      if (results === null) {
        return [];
      }

      return results;
    });
  });

/**
 * Get an instance of the Google Maps Places Service.
 * Because this function creates a div we'll wrap it in a iife and cache the promise
 *
 * @returns {Promise}
 * @resolve {object} https://developers.google.com/maps/documentation/javascript/reference#PlacesService
 */
const getPlacesService = (() => {
  let placesPromise;

  return () => {
    if (placesPromise !== undefined) {
      return placesPromise;
    }

    placesPromise = loadGoogle().then(
      (google) =>
        new google.maps.places.PlacesService(document.createElement("div"))
    );

    return placesPromise;
  };
})();

/**
 * Place lookup by query
 *
 * @param query
 * @param fields
 * @returns {Promise}
 * @resolve {object} https://developers.google.com/maps/documentation/javascript/reference#PlaceResult
 */
export const findPlaceFromQuery = (
  query,
  fields = ["formatted_address", "place_id"]
) =>
  getPlacesService().then((placesService) => {
    const findQuery = promisify(
      placesService.findPlaceFromQuery.bind(placesService)
    );
    return findQuery({ query, fields, locationBias: "IP_BIAS" });
  });

/**
 * Retrieves details about the place identified by the given placeId.
 *
 * @param placeId
 * @param sessionToken
 * @returns {Promise}
 * @resolve {object} https://developers.google.com/maps/documentation/javascript/reference#PlaceResult
 */
export const getPlaceDetails = (placeId, sessionToken) =>
  getPlacesService().then((placesService) => {
    const getDetails = promisify(placesService.getDetails.bind(placesService));
    const fields = ["name", "geometry", "address_components"];
    return getDetails({ placeId, fields, sessionToken });
  });

/**
 * Returns a google LatLng object for the given coords
 * https://developers.google.com/maps/documentation/javascript/reference#LatLng
 *
 * @param lat
 * @param lng
 * @returns {Promise}
 * @resolve {object} LatLng
 */
export const getLatLng = (lat, lng) =>
  loadGoogle().then((google) => new google.maps.LatLng(lat, lng));

/**
 * Returns a LatLngBounds object which represents a rectangle as defined by the input params
 *
 * @param southWestLatLng LatLng
 * @param northEastLatLng LatLng
 * @returns {Promise}
 * @resolve {object} https://developers.google.com/maps/documentation/javascript/reference#LatLngBounds
 */
export const getLatLngBounds = (southWestLatLng, northEastLatLng) =>
  loadGoogle().then(
    (google) => new google.maps.LatLngBounds(southWestLatLng, northEastLatLng)
  );

/**
 * Convenience method to return a hardcoded value for a LatLngBounds around the UK
 */
export const getUkLatLngBounds = () => {
  return {
    south: 49.651626,
    west: -11.074219,
    north: 61.131303,
    east: 1.230469
  };
};

/**
 * Turns a Position object returned from the browser into a google LatLng object
 *
 * @param {object} geolocationPosition https://developer.mozilla.org/en-US/docs/Web/API/Position
 * @returns {Promise}
 * @resolve {object} LatLng
 */
export const geolocationToLatLng = (geolocationPosition) =>
  getLatLng(
    geolocationPosition.coords.latitude,
    geolocationPosition.coords.longitude
  );

/**
 * Returns a Google Geocoder Object
 * https://developers.google.com/maps/documentation/javascript/reference#Geocoder
 *
 * @returns {Promise}
 * @resolve {object}
 */
const getGeocoder = () =>
  loadGoogle().then((google) => new google.maps.Geocoder());

/**
 * Geocode a request.
 *
 * @param {object} geocoderRequest https://developers.google.com/maps/documentation/javascript/reference#GeocoderRequest
 * @resolve {object} GeocoderResult https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
 */
const geocode = (geocoderRequest) =>
  getGeocoder().then((geocoder) => {
    const getGeocode = promisify(geocoder.geocode.bind(geocoder));
    return getGeocode(geocoderRequest);
  });

/**
 * Reverse geocode a LatLng object into an address
 * Convenice wrapper around the above geocode function
 * @param LatLng
 */
export const geocodeLatLng = (LatLng) =>
  geocode({
    location: LatLng
  });

/**
 * Geocode an address
 * Convenice wrapper around the above geocode function
 * @param address
 */
export const geocodeAddress = (address) =>
  geocode({
    address
  });
