// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Cross from "../../../svg/cross.svg";
import "./crossIcon.scss";

type Props = {
  modifier?: "cancel" | "error";
  className?: string;
  onClick?: () => void;
  cypress?: string | null;
};
const CrossIcon = ({
  modifier = "cancel",
  className = "",
  onClick = () => {},
  cypress = null
}: Props) => (
  <Cross
    className={`${bem("c-cross-icon", null, {
      modifier
    })} ${className}`}
    onClick={onClick}
    data-cy={cypress}
  />
);

export default CrossIcon;
