import React, { useCallback, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useRouteError } from '@not-remix-run/react';
import { ErrorBoundary } from 'react-error-boundary';
import { notify, leaveBreadcrumb } from '../helpers/bugsnag/BugsnagClient';

import '../../static/scss/components/globalErrorBoundary.scss';

type Props = {
  children: any;
};
type FallbackProps = {
  resetErrorBoundary: () => void;
  status: number;
};

const FallbackComponent = ({ resetErrorBoundary, status }: FallbackProps) => {
  const location = useLocation();
  const initialLocation = useRef(location.key);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialLocation.current !== location.key) {
      resetErrorBoundary();
    }
  }, [location, resetErrorBoundary]);

  if (status === 403) {
    return (
      <h1 data-cy="forbidden">
        <Trans i18nKey="global:forbidden">403 : Forbidden</Trans>
      </h1>
    );
  }

  if (status === 404) {
    return (
      <h1>
        <Trans i18nKey="global:404">404 : Not Found</Trans>
      </h1>
    );
  }

  return (
    <div className="c-global-error-boundary">
      <h1>{t('globalError:wentWrong', 'Something went wrong.')}</h1>
      <p>
        {t(
          'globalError:problem',
          'There has been a problem fulfilling your request'
        )}
      </p>
      <p>
        <Trans i18nKey="globalError:reload">
          Please{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.reload();
            }}
          >
            reload
          </a>{' '}
          this page and try again or ask for{' '}
          <a href="https://support-uk.justpark.com/hc/en-us">help</a> here
        </Trans>
      </p>
    </div>
  );
};

const GlobalErrorBoundary = ({ children }: Props) => {
  const onError = useCallback((error, info) => {
    leaveBreadcrumb('GlobalErrorBoundary shown');
    notify(error, info);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

export const RouteErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    leaveBreadcrumb('GlobalErrorBoundary shown');
    notify(error);
  }, [error]);

  return (
    <FallbackComponent status={error?.status} resetErrorBoundary={() => {}} />
  );
};

export default GlobalErrorBoundary;
