import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const reducerName = 'partner';
type State = {
  partnerName: string;
  locale: string;
};

export const initialState: State = {
  partnerName: '',
  locale: ''
};

const partnerSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setPartner: (state, action: PayloadAction): State => ({
      ...state,
      partnerName: action.payload
    }),
    setLocale: (state, action: PayloadAction): State => ({
      ...state,
      locale: action.payload
    })
  }
});

export const { setPartner, setLocale } = partnerSlice.actions;

export const selectIsAppFeatureLimited = (state) =>
  state[reducerName].partnerName !== '';

export const selectLanguage = (state) => state[reducerName]?.locale;

export const selectPartnerName = (state) => state[reducerName].partnerName;

export const selectPartnerIsWelshLocale = createSelector(
  selectLanguage,
  (language) => language === 'cy'
);

export default partnerSlice;
