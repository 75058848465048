import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import evFleetsFinishSignup from '@justpark/api/src/requests/evFleetsFinishSignup';
import getRequestError from '@justpark/api/src/helpers/getRequestError';

const sliceName = 'evWelcome';

export const PAYMENT_STAGE = 'PAYMENT_STAGE';
export const SUCCESS_STAGE = 'SUCCESS_STAGE';

export type State = {
  stage: PAYMENT_STAGE | SUCCESS_STAGE;
  linkPaymentIsLoading: boolean;
  linkPaymentError: string;
};

export const initialState: State = {
  stage: PAYMENT_STAGE,
  linkPaymentIsLoading: false,
  linkPaymentError: ''
};

export const linkPaymentMethodToFleet = createAsyncThunk(
  'evWelcome/linkPaymentMethodToFleet',
  async (payload, { extra: { jpApiClient }, rejectWithValue }) => {
    try {
      const request = evFleetsFinishSignup(payload);
      const apiResponse = await jpApiClient(request);
      const { data } = apiResponse;
      return data;
    } catch (e) {
      return rejectWithValue(getRequestError(e));
    }
  }
);

const evWelcomeSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      linkPaymentMethodToFleet.pending,
      (state: State): State => ({
        ...state,
        linkPaymentIsLoading: true
      })
    );
    builder.addCase(
      linkPaymentMethodToFleet.fulfilled,
      (state: State): State => ({
        ...state,
        linkPaymentIsLoading: false,
        stage: SUCCESS_STAGE
      })
    );
    builder.addCase(
      linkPaymentMethodToFleet.rejected,
      (state: State, action: PayloadAction): State => ({
        ...state,
        linkPaymentIsLoading: false,
        linkPaymentError: action.payload
      })
    );
  }
});

export const selectEvWelcomeStage = (state) => state[sliceName].stage;
export const selectEvWelcomeLinkIsLoading = (state) =>
  state[sliceName].linkPaymentIsLoading;
export const selectEvWelcomeLinkError = (state) =>
  state[sliceName].linkPaymentError;

export default evWelcomeSlice;
