import React, { useState, useEffect, createContext, useContext } from 'react';
import scriptLoader from '@justpark/helpers/src/scriptLoader/scriptLoader';
import { waitForCookiePolicy } from '../../../helpers/cookiePolicy';
import config from '../../../../config/config';
import useDarkly from '../../../hooks/useDarkly';

type Props = {
  children: any;
  accountId: string;
};

export const RoktLauncherContext = createContext(null);

export function useRoktLauncher() {
  return useContext(RoktLauncherContext);
}

const RoktContextProvider = ({ children, accountId }: Props) => {
  const [launcher, setLauncher] = useState(null);
  const sandbox = !config.isProduction;
  const onetrust = useDarkly('onetrust');

  useEffect(() => {
    let launcherInstance;

    (async () => {
      if (onetrust) {
        await waitForCookiePolicy();
      }

      await scriptLoader(
        'https://apps.rokt.com/wsdk/integrations/launcher.js',
        true,
        false,
        [
          {
            key: 'crossorigin',
            value: 'anonymous'
          },
          {
            key: 'type',
            value: 'module'
          }
        ]
      );

      const functionalCookiesGroup = 'C0003';
      const targetingCookiesGroup = 'C0004';

      const cookies = window?.OptanonActiveGroups?.split(',') || [
        functionalCookiesGroup,
        targetingCookiesGroup
      ];

      launcherInstance = await window.Rokt.createLauncher({
        accountId,
        sandbox,
        nofunctional: cookies.indexOf(functionalCookiesGroup) === -1,
        notargeting: cookies.indexOf(targetingCookiesGroup) === -1
      });

      setLauncher(launcherInstance);
    })().catch((err) => {
      console.error(err);
    });

    return () => {
      if (launcherInstance) {
        launcherInstance.terminate();
        setLauncher(null);
      }
    };
  }, [accountId, sandbox, onetrust]);

  return (
    <RoktLauncherContext.Provider value={launcher}>
      {children}
    </RoktLauncherContext.Provider>
  );
};

export default RoktContextProvider;
