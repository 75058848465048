import type { AxiosPromise, AxiosInstance } from "axios";

type Args = {
  referrer: string;
  requestUri: string;
  affiliateId: number | null;
  inviteCode: string | null;
};

const trackingLongSession = ({
  referrer,
  requestUri,
  affiliateId,
  inviteCode
}: Args): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v5/users/session", {
    referrer,
    affiliate_id: affiliateId,
    invite_code: inviteCode,
    request_uri: requestUri
  });
};

export default trackingLongSession;
