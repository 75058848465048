import { createContext } from 'react';

const BrandContext = createContext({
  primaryColor: null,
  onPrimaryColor: null,
  secondaryColor: null,
  onSecondaryColor: null,
  logo: null,
  isWhiteLabeled: false
});

BrandContext.displayName = 'BrandContext';

export default BrandContext;
