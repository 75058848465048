/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Linkedin from '@justpark/ui/src/components/FontAwesome/Linkedin';
import Facebook from '@justpark/ui/src/components/FontAwesome/Facebook';
import Instagram from '@justpark/ui/src/components/FontAwesome/Instagram';
import Twitter from '@justpark/ui/src/components/FontAwesome/Twitter';

import styles from '../../../../static/scss/components/footer.module.scss';

const SocialLinks = () => (
  <ul className={styles.unstyledList}>
    <li className={styles.socialListItem}>
      <a
        href="https://www.facebook.com/JustParkHQ/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="facebook"
      >
        <Facebook className={styles.icon} size={16} />
      </a>
    </li>

    <li className={styles.socialListItem}>
      <a
        href="https://www.instagram.com/justparkhq/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <Instagram className={styles.icon} size={16} />
      </a>
    </li>

    <li className={styles.socialListItem}>
      <a
        href="https://twitter.com/JustPark"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="twitter"
      >
        <Twitter className={styles.icon} size={16} />
      </a>
    </li>

    <li className={styles.socialListItem}>
      <a
        href="https://www.linkedin.com/company/justpark"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <Linkedin className={styles.icon} size={16} />
      </a>
    </li>
  </ul>
);

export default SocialLinks;
