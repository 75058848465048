const environment = {
  development: {
    isProduction: false,
    isStaging: false,
    isDevelopment: true,
    isTesting: false,
    assetPath: '/dist/'
  },
  staging: {
    isProduction: false,
    isStaging: true,
    isDevelopment: false,
    isTesting: false,
    assetPath: 'https://static-staging.justpark.me/web/'
  },
  production: {
    isProduction: true,
    isStaging: false,
    isDevelopment: false,
    isTesting: false,
    assetPath: 'https://static.justpark.com/web/'
  },
  test: {
    isTesting: true,
    isDevelopment: true,
    assetPath: '/dist/'
  }
}[process.env.RELEASE_STAGE || process.env.NODE_ENV || 'development'];
const metaDescription = 'Find parking, wherever and whenever you need it, online or via the JustPark app. Reserve a space at over 45,000 locations. Trusted by 10 million drivers.';
const frozenConfig = Object.freeze({
  port: import.meta.env.PORT || 3000,
  appPort: 3100,
  apiHost: import.meta.env.API_HOST || 'pig.justpark.me',
  apiPort: import.meta.env.API_PORT || '',
  // The API_PROTOCOL is the only ENV var that is defined during run time on the Web docker image.
  // When the app is compiled it uses https, but when the server is run this is change to http as the internal cluster URLs work on http.
  apiProtocol: typeof process !== 'undefined' ? process.env.API_PROTOCOL : import.meta.env.API_PROTOCOL || 'https',
  apiProxyEanbled: import.meta.env.API_USE_PROXY === 'true',
  appVersion: import.meta.env.APP_VERSION || '0.0.0',
  awinAdvertiserId: 6188,
  app: {
    title: 'JustPark',
    description: metaDescription,
    head: {
      titleTemplate: '%s | JustPark',
      meta: [{
        name: 'robots',
        content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
      }, {
        name: 'description',
        content: metaDescription
      }, {
        name: 'format-detection',
        content: 'telephone=no'
      }, {
        name: 'keywords',
        content: 'parking spaces for rent, car parking, london parking, rent your driveway, let out your space, garages for rent, stadium parking, airport parking, garage rentals, parking, garages, driveways, rent, let'
      }, {
        name: 'apple-mobile-web-app-capable',
        content: 'yes'
      }, {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=6.0'
      }, {
        name: 'HandheldFriendly',
        content: 'True'
      }, {
        name: 'MobileOptimized',
        content: '320'
      }, {
        httpEquiv: 'cleartype',
        content: 'on'
      }, {
        name: 'apple-mobile-web-app-capable',
        content: 'yes'
      },
      // Facebook
      {
        property: 'og:site_name',
        content: 'JustPark'
      }, {
        property: 'og:type',
        content: 'website'
      }, {
        property: 'og:url',
        content: 'www.justpark.com'
      }, {
        property: 'og:title',
        content: 'JustPark'
      }, {
        property: 'og:locale',
        content: 'en_GB'
      }, {
        property: 'og:description',
        content: metaDescription
      }, {
        property: 'fb:admins',
        content: '1205769'
      }, {
        property: 'fb:app_id',
        content: '2555327138'
      }, {
        property: 'fb:page_id',
        content: '159346887409884'
      },
      // Twitter
      {
        name: 'twitter:card',
        content: 'summary'
      }, {
        name: 'twitter:site',
        content: '@justpark'
      }, {
        name: 'twitter:title',
        content: 'JustPark'
      }, {
        name: 'twitter:description',
        content: metaDescription
      }, {
        name: 'twitter:creator',
        content: '@justpark'
      }, {
        name: 'twitter:card',
        content: 'summary'
      }, {
        name: 'twitter:image:src',
        content: 'http://www.justpark.com/media/img/logos/56-colour.png'
      }, {
        name: 'twitter:domain',
        content: '"www.justpark.com'
      },
      // Pintrest
      {
        name: 'p:domain_verify',
        content: '6b6471f9f8060b2fda29a92e1b05fcd3'
      },
      // Google
      {
        href: 'https://plus.google.com/107589855752054222705',
        rel: 'publisher'
      },
      // Favicon
      {
        name: 'msapplication-TileColor',
        content: '#ffffff'
      }, {
        name: 'msapplication-TileImage',
        content: '/images/favicon/ms-icon-144x144.png'
      }],
      link: [{
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/images/favicon/apple-icon-57x57.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: '/images/favicon/apple-icon-60x60.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/images/favicon/apple-icon-72x72.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/images/favicon/apple-icon-76x76.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/images/favicon/apple-icon-114x114.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/images/favicon/apple-icon-120x120.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/images/favicon/apple-icon-144x144.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/images/favicon/apple-icon-152x152.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/images/favicon/apple-icon-180x180.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/images/favicon/apple-icon-152x152.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/images/favicon/apple-icon-152x152.png'
      }, {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/images/favicon/apple-icon-152x152.png'
      }, {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: '/images/favicon/android-icon-192x192.png'
      }, {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/images/favicon/favicon-32x32.png'
      }, {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: '/images/favicon/favicon-96x96.png'
      }, {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/images/favicon/favicon-16x16.png'
      }, {
        rel: 'manifest',
        href: '/images/favicon/manifest.json'
      }, {
        rel: 'shortcut icon',
        type: 'image/png',
        href: '/media/img/assets/iphone/icons/57x57.png'
      }, {
        rel: 'apple-touch-startup-image',
        sizes: '640x960',
        href: '/media/img/assets/iphone/banners/640x960.jpg'
      }, {
        rel: 'apple-touch-startup-image',
        href: '/media/img/assets/iphone/banners/320x480.jpg'
      }, {
        rel: 'shortcut icon',
        href: '/media/img/assets/iphone/icons/57x57.png'
      }, {
        rel: 'shortcut icon',
        href: '/media/img/assets/iphone/icons/57x57.png'
      }, {
        rel: 'shortcut icon',
        href: '/media/img/assets/iphone/icons/57x57.png'
      }]
    },
    consts: {
      landingModifier: 'landing'
    }
  },
  ...environment
});
export default frozenConfig;