import popupWindowParams from '@justpark/helpers/src/popupWindowParams/popupWindowParams';
import { oauthTokenEmitter } from '../components/Auth/OAuthTokenReceiver';

const googleLogin = () =>
  new Promise((resolve) => {
    const url = `/auth/socialite/google`;

    window.open(url, 'googleLogin', popupWindowParams());

    oauthTokenEmitter.once('google_token', (token) => {
      resolve({ access_token: token });
    });
  });

export default googleLogin;
