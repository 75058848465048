/* eslint-disable import/no-cycle */
import _debug from 'debug';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getPartnerWhiteLabel from '@justpark/api/src/requests/getPartnerWhiteLabel';
import { makeActionCreator } from '../helpers/utils';
import { CHECKOUT_LOAD_LISTING } from './actions/shared';

const debug = _debug('jp:reducers:brand');

export const UPDATE_BRAND = 'brand:update_brand';
export const RESET_BRAND = 'brand:reset_brand';

export type State = {
  primaryColor: string | null;
  secondaryColor: string | null;
  onPrimaryColor: string | null;
  onSecondaryColor: string | null;
  logo: string | null; // Standard logo for light backgrounds
  logoInverse: string | null; // Logo for darker backgrounds
  isWhiteLabeled: boolean;
  partnerWhiteLabelError: boolean;
  partner: string;
};

export const initialState: State = {
  primaryColor: null,
  onPrimaryColor: null,
  secondaryColor: null,
  onSecondaryColor: null,
  logo: null,
  logoInverse: null,
  isWhiteLabeled: false,
  partnerWhiteLabelError: false,
  partner: ''
};

export const loadPartnerWhiteLabel = createAsyncThunk(
  'brand/loadPartnerWhiteLabel',
  async (payload, { extra: { jpApiClient }, rejectWithValue }) => {
    try {
      const request = getPartnerWhiteLabel(payload);
      const response = await jpApiClient(request);

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const sliceName = 'brand';

const brandSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      UPDATE_BRAND,
      (state: State, action): State => {
        debug(UPDATE_BRAND, action);
        const {
          primaryColour,
          secondaryColour,
          onPrimaryColour,
          onSecondaryColour,
          logoUrl,
          invertedLogoUrl
        } = action.brandData;

        return {
          ...state,
          primaryColor: primaryColour,
          secondaryColor: secondaryColour,
          onPrimaryColor: onPrimaryColour,
          onSecondaryColor: onSecondaryColour,
          logo: logoUrl,
          logoInverse: invertedLogoUrl,
          isWhiteLabeled: true
        };
      }
    );
    builder.addCase(
      loadPartnerWhiteLabel.pending,
      (state: State): State => {
        debug('loadPartnerWhiteLabel.pending');

        return {
          ...state,
          partnerWhiteLabelError: false
        };
      }
    );
    builder.addCase(
      loadPartnerWhiteLabel.fulfilled,
      (state: State, action: Action): State => {
        debug('loadPartnerWhiteLabel.fulfilled', action);

        if (action && action.payload) {
          const {
            primaryColour,
            onPrimaryColour,
            secondaryColour,
            onSecondaryColour,
            logoUrl,
            invertedLogoUrl,
            companyName
          } = action.payload[0];

          return {
            ...state,
            primaryColor: primaryColour,
            secondaryColor: secondaryColour,
            onPrimaryColor: onPrimaryColour,
            onSecondaryColor: onSecondaryColour,
            logoInverse: invertedLogoUrl,
            logo: logoUrl,
            isWhiteLabeled: true,
            partner: companyName.toLowerCase()
          };
        }

        return {
          ...state
        };
      }
    );
    builder.addCase(
      loadPartnerWhiteLabel.rejected,
      (state: State, action: Action): State => {
        debug('loadPartnerWhiteLabel.rejected', action);

        return {
          ...state,
          partnerWhiteLabelError: true
        };
      }
    );
    builder.addCase(
      `${CHECKOUT_LOAD_LISTING}/fulfilled`,
      (state: State, action: PayloadAction): State => {
        debug(`${CHECKOUT_LOAD_LISTING}/fulfilled`, action);

        if (action.payload && action.payload.data) {
          const listing = action.payload.data;

          if (listing.whiteLabel && !state.isWhiteLabeled) {
            const {
              logoUrl,
              invertedLogoUrl,
              primaryColour,
              secondaryColour,
              onPrimaryColour,
              onSecondaryColour
            } = listing.whiteLabel;

            return {
              ...state,
              primaryColor: primaryColour,
              secondaryColor: secondaryColour,
              onPrimaryColor: onPrimaryColour,
              onSecondaryColor: onSecondaryColour,
              logo: logoUrl,
              logoInverse: invertedLogoUrl,
              isWhiteLabeled: true,
              partner: ''
            };
          }
        }

        return state;
      }
    );
    builder.addCase(
      RESET_BRAND,
      (state: State): State => ({
        ...state,
        ...initialState
      })
    );
  }
});

export default brandSlice;

export const updateBrand = makeActionCreator(UPDATE_BRAND, 'brandData');

export const selectBrandPrimaryColor = (state) =>
  state[brandSlice.name].primaryColor;

export const selectBrandOnPrimaryColor = (state) =>
  state[brandSlice.name].onPrimaryColor;

export const selectBrandSecondaryColor = (state) =>
  state[brandSlice.name].secondaryColor;

export const selectBrandOnSecondaryColor = (state) =>
  state[brandSlice.name].onSecondaryColor;

export const selectBrandIsWhiteLabeled = (state) =>
  state[brandSlice.name].isWhiteLabeled;

export const selectBrandWhiteLabelError = (state) =>
  state[brandSlice.name].partnerWhiteLabelError;

export const selectBrandPartner = (state) => state[brandSlice.name].partner;
export const resetBrand = makeActionCreator(RESET_BRAND);
