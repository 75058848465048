/* eslint-disable import/prefer-default-export */
/**
 * A place for us to keep shared actions.
 * If you have an action that you want picked up by two slices put it here
 * This will allow us to lazy load dependent slices
 */

export const SUBMIT_DETAILS_SUCCESS = 'profile/submitDetails/fulfilled';

export const CHECKOUT_LOAD_LISTING = 'checkout/loadListing';

export const MONTHLY_CHECKOUT_CREATE_BOOKING = 'monthlyCheckout/createBooking';

export const clearSensitiveData = () => ({
  type: 'monthlyCheckout/clearSensitiveData'
});

export const setInitialVehicleState = () => ({
  type: 'vehicle/setInitialState'
});

export const setInitialPaymentStateActionName = 'payments/SET_INITIAL_STATE';

export const setInitialPaymentState = () => ({
  type: setInitialPaymentStateActionName
});
