import { useEffect } from 'react';
import { useLocation } from '@not-remix-run/react';
import { useDispatch, useSelector } from 'react-redux';
import { resetBrand, selectBrandIsWhiteLabeled } from '../stores/brand';
import { selectMonthlyCheckoutListingWhiteLabel } from '../stores/selectors/monthlyCheckout';

const useResetWhiteLabel = () => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();

  const whiteLabelListingCheckout = useSelector(
    selectMonthlyCheckoutListingWhiteLabel
  );

  const locationIsPartner = pathname && pathname.substr(0, 9) === '/partner/';
  const isWhiteLabeled = useSelector(selectBrandIsWhiteLabeled);

  useEffect(() => {
    if (isWhiteLabeled && !locationIsPartner && !whiteLabelListingCheckout) {
      dispatch(resetBrand());
    }
  }, [
    pathname,
    isWhiteLabeled,
    dispatch,
    locationIsPartner,
    whiteLabelListingCheckout
  ]);
};

export default useResetWhiteLabel;
