import { useSyncExternalStore } from 'react';
import mediaquery from 'mediaquery';
import breakpoints from '../helpers/breakpoints';

breakpoints.infinity = Infinity;
const breakpointKeys = Object.keys(breakpoints);
const mediaQueries = mediaquery.asObject(breakpoints);
const getLastItem = (map) => {
  if (map.size === 0) return undefined;
  const keys = Array.from(map.keys());
  return map.get(keys[keys.length - 1]);
};

const getMediaType = () =>
  typeof window !== 'undefined' &&
  Object.keys(mediaQueries).reduce(
    (result, key) =>
      window.matchMedia(mediaQueries[key]).matches ? key : result,
    ''
  );

const getGreaterThan = (mediaType: string) => {
  if (typeof window === 'undefined') return new Map();

  return Object.keys(breakpoints).reduce((acc, value, index) => {
    const lastItem = getLastItem(acc);
    acc.set(
      value,
      !(breakpointKeys[index] === mediaType || lastItem === false)
    );
    return acc;
  }, new Map());
};

const getLessThan = (mediaType: string) => {
  if (typeof window === 'undefined') return new Map();

  return Object.keys(breakpoints).reduce((acc, value, index) => {
    const lastItem = getLastItem(acc);
    acc.set(
      value,
      !!(breakpointKeys[index - 1] === mediaType || lastItem === true)
    );
    return acc;
  }, new Map());
};

const getIs = (mediaType: string) => {
  if (typeof window === 'undefined') return new Map();

  return Object.keys(breakpoints).reduce((result, key) => {
    result.set(key, key === mediaType);
    return result;
  }, new Map());
};

const initialMediaType = getMediaType();

const initialBrowserState = {
  breakpoints,
  greaterThan: Object.fromEntries(getGreaterThan(initialMediaType)),
  is: Object.fromEntries(getIs(initialMediaType)),
  lessThan: Object.fromEntries(getLessThan(initialMediaType)),
  mediaType: initialMediaType
};

const browserStore = {
  browser: initialBrowserState,

  getSnapshot() {
    const { browser } = browserStore;

    const mediaType = getMediaType();

    if (mediaType === browser.mediaType) {
      return browser;
    }

    browserStore.browser = {
      ...browser,
      greaterThan: Object.fromEntries(getGreaterThan(mediaType)),
      is: Object.fromEntries(getIs(mediaType)),
      lessThan: Object.fromEntries(getLessThan(mediaType)),
      mediaType
    };

    return browserStore.browser;
  },

  getServerSnapshot() {
    return browserStore.browser;
  }
};

const subscribe = (callback) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.matchMedia !== 'undefined'
  ) {
    const listeners = Object.values(mediaQueries).map((query) => {
      const mediaQueryList = window.matchMedia(query);
      mediaQueryList.addEventListener('change', callback);
      return mediaQueryList;
    });

    return () => {
      listeners.forEach((mediaQueryList) => {
        mediaQueryList.removeEventListener('change', callback);
      });
    };
  }

  return () => {};
};

const useBrowserStore = () =>
  useSyncExternalStore(
    subscribe,
    browserStore.getSnapshot,
    browserStore.getServerSnapshot
  );

export default useBrowserStore;
