const stripData = (obj, partsArray) => {
  let pointer = obj;

  partsArray.slice(0, -1).forEach((part) => {
    if (!(part in pointer)) {
      throw new Error(`${part} not found in transformIncludes`);
    }

    pointer = pointer[part]?.data || pointer[part];
  });

  const lastItem = partsArray[partsArray.length - 1];
  pointer[lastItem] = pointer[lastItem]?.data || pointer[lastItem];
};

const transformIncludes = (data, includes) => {
  const transformedData = JSON.parse(JSON.stringify(data));
  const splitIncludes = includes.map((include) => include.split("."));

  splitIncludes
    .sort((a, b) => a.length < b.length)
    .forEach((partsArray) => {
      if (Array.isArray(transformedData.data)) {
        return transformedData.data.forEach((item) => {
          stripData(item, partsArray);
        });
      }
      return stripData(transformedData.data, partsArray);
    });

  return transformedData;
};

export default transformIncludes;
