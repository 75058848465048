import React from 'react';
import { useTranslation } from 'react-i18next';
import LegacyLink from '../../Common/LegacyLink';
import Zendesk from '../../Common/ZendeskLink';

type Props = {
  styles: {
    list: string;
    navigationLinks: string;
  };
};

const GetInTouchLinks = ({ styles }: Props) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      <li>
        <LegacyLink
          to="how-it-works/overview/"
          className={styles.navigationLinks}
        >
          {t('footer:howJPWorks', 'How JustPark works')}
        </LegacyLink>
      </li>
      <li>
        <Zendesk isFooter className={styles.navigationLinks}>
          {t('footer:helpCentre', 'Help centre')}
        </Zendesk>
      </li>
    </ul>
  );
};

export default GetInTouchLinks;
