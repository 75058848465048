import * as React from 'react';
import debug from 'debug';
import { map } from 'redux-data-structures';
import { createAction } from 'redux-actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

const log = debug('jp:form');

const STATE_KEY = 'form';

const UPDATE_STORE = '@@form/UPDATE';
const CREATE_STORE = '@@form/CREATE';
const REMOVE_STORE = '@@form/REMOVE';

const meta = (name: string) => ({ name });
const payload = (name: string, opts = {}) => opts;
const toFormState = (f, p) => (typeof f === 'function' ? f(p) : f);

const update = createAction(UPDATE_STORE, payload, meta);
const create = createAction(CREATE_STORE, payload, meta);
const remove = createAction(REMOVE_STORE, payload, meta);

export const reducer = map({
  keyGetter: (action) => action.meta.name,
  itemModifier: (item, action) => {
    if (action.payload.model) {
      return {
        ...item,
        model: {
          ...item.model,
          ...action.payload.model
        }
      };
    }

    if (
      action.payload.dirtyStates &&
      Object.keys(action.payload.dirtyStates).length
    ) {
      return {
        ...item,
        dirtyStates: {
          ...item.dirtyStates,
          ...action.payload.dirtyStates
        }
      };
    }

    return { ...item, ...action.payload };
  },
  addActionTypes: [CREATE_STORE],
  changeActionTypes: [UPDATE_STORE],
  removeActionTypes: [REMOVE_STORE]
});

// Decorator to handle formState updates to dynamic redux store
const withFormState = ({ name, initialState }) =>
  compose(
    connect(
      (state, ownProps) => ({
        form:
          state[`${STATE_KEY}`]?.byId[`${name}`] ||
          toFormState(initialState, ownProps)
      }),
      {
        remove: (vals: Record<string, any>) => remove(name, vals),
        update: (vals: Record<string, any>) => update(name, vals),
        create: (vals: Record<string, any>) => create(name, vals)
      }
    )
  );

const makeWrapper = (
  formName: string,
  initialModel: Record<string, any>,
  middleware?: (() => any) | null
) => {
  // Set up the initial state of the form
  const initialState = {
    model: initialModel || {},
    dirtyStates: {},
    hasBlurred: []
  };
  type Props = {
    form: {
      model: Record<string, any>;
      dirtyStates: Record<string, any>;
      hasBlurred: any[];
    };
    onRef?: (a: any) => void;
    update: (vals: Record<string, any>) => void;
    create: (vals: Record<string, any>) => void;
  };

  return (WrappedComponent: React.Component<any>) => {
    class FormWrapper extends React.Component<Props, void> {
      componentDidMount() {
        this.props.create(toFormState(initialState, this.props));
        if (typeof this.props.onRef === 'function') {
          this.props.onRef(this);
        }
      }

      componentWillUnmount() {
        if (typeof this.props.onRef === 'function') {
          this.props.onRef(undefined);
        }
      }

      setModel = (model: any, cb?: (() => void) | null) => {
        log('setModel:', model);
        this.props.update({
          model: {
            ...model
          }
        });

        if (typeof cb === 'function') {
          cb();
        }
      };

      setDirty = (prop: string) => {
        log('setDirty:', prop);
        this.props.update({
          dirtyStates: {
            [prop]: true
          }
        });
      };

      setProperty = (prop: string, value: any) => {
        log('setProperty:', prop, value);
        const newProp = {
          [prop]: value
        };

        this.setDirty(prop);

        this.props.update({
          model: {
            ...newProp
          }
        });
      };

      clearDirty = () => {
        this.props.update({
          dirtyStates: {}
        });
      };

      bindToChangeEvent = (e: SyntheticEvent) => {
        const { name, type, value } = e.target;
        log('bindToChangeEvent [name,type,value]', name, type, value);

        if (type === 'checkbox') {
          const oldCheckboxValue = this.props.form.model[name] || [];
          const newCheckboxValue = e.target.checked
            ? oldCheckboxValue.concat(value)
            : oldCheckboxValue.filter((v) => v !== value);

          this.setProperty(name, newCheckboxValue);
        } else {
          this.setProperty(name, value);
        }
      };

      bindToBlurEvent = (e: SyntheticEvent) => {
        const { name } = e.target;
        log('bindToBlurEvent [name]', name);
        const blurred = [...this.props.form.hasBlurred];
        if (blurred.indexOf(name) < 0) {
          blurred.push(name);
        }

        this.props.update({
          hasBlurred: blurred
        });
      };

      bindInput = (name) => ({
        name,
        value: this.props.form.model[name] || '',
        onChange: this.bindToChangeEvent,
        onBlur: this.bindToBlurEvent
      });

      render() {
        const nextProps = {
          ...this.props,
          ...{
            bindInput: this.bindInput,
            bindToChangeEvent: this.bindToChangeEvent,
            model: this.props.form.model,
            dirtyStates: this.props.form.dirtyStates,
            hasBlurred: this.props.form.hasBlurred,
            setProperty: this.setProperty,
            setModel: this.setModel,
            bindToBlurEvent: this.bindToBlurEvent,
            clearDirty: this.clearDirty
          }
        };

        const finalProps =
          typeof middleware === 'function' ? middleware(nextProps) : nextProps;

        return React.createElement(WrappedComponent, finalProps);
      }
    }

    FormWrapper.defaultProps = {
      onRef: () => {}
    };

    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';

    FormWrapper.displayName = `Formed(${displayName})`;

    return hoistNonReactStatics(
      withFormState({ name: formName, initialState })(FormWrapper),
      WrappedComponent
    );
  };
};

export default makeWrapper;
