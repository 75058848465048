import type { AxiosPromise, AxiosInstance } from "axios";

const googleLogin = (
  accessToken: string,
  queryParams: any,
  suppressVerificationEmail: boolean
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v5/auth/google", {
    access_token: accessToken,
    params: queryParams,
    include: "booking_summary",
    suppress_verification_email: suppressVerificationEmail
  });
};

export default googleLogin;
