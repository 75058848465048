import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import applyCookiePromo from '@justpark/api/src/requests/applyCookiePromo';
import getRequestErrorMessageCode from '@justpark/api/src/helpers/getRequestErrorMessageCode';

export type State = {
  response: string;
  successCount: number;
};

export const initialState: State = {
  response: '',
  successCount: 0
};

const sliceName = 'promotions';

const getResponseFromCode = (code) => {
  if (code === 60003) {
    return 'promo_code_doesnt_exist';
  }

  if (code === 60004) {
    return 'promo_code_already_redeemed';
  }

  return 'promo_code_unredeemed';
};

export const applyPromotion = createAsyncThunk(
  `${sliceName}/applyPromotion`,
  async (page, { rejectWithValue, extra: { jpApiClient } }) => {
    try {
      const request = applyCookiePromo();
      const { data } = await jpApiClient(request);

      return data;
    } catch (e) {
      const { code } = getRequestErrorMessageCode(e);
      const state = getResponseFromCode(code);

      return rejectWithValue(state);
    }
  }
);

const promotionsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      applyPromotion.pending,
      (state: State): State => ({
        ...state,
        response: ''
      })
    );
    builder.addCase(
      applyPromotion.fulfilled,
      (state: State): State => ({
        ...state,
        response: 'success',
        successCount: state.successCount + 1
      })
    );
    builder.addCase(
      applyPromotion.rejected,
      (state: State, action): State => ({
        ...state,
        response: action.payload
      })
    );
  }
});

export default promotionsSlice;

export const selectPromotionsState = (state) =>
  state[promotionsSlice.name].response;

export const selectPromotionsSuccessCount = (state) =>
  state[promotionsSlice.name].successCount;
