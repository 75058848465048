import React, { useState, useEffect, useContext } from 'react';
import { Link } from '@not-remix-run/react';
import { useLocation } from '@not-remix-run/react';
import ScrollLock from 'react-scrolllock';
import * as querystring from 'query-string';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MobileMenuIcon from '@justpark/ui/src/components/Icons/MobileMenuIcon';
import LegacyLink from '../Common/LegacyLink';
import Logo from '../UI/Logo';
import LogoSmall from '../../../static/images/header_logo_small.svg';
import PrimaryNav from './HeaderPartials/PrimaryNav';
import { selectPartnerName } from '../../stores/partner';
import SecondaryNav from './HeaderPartials/SecondaryNav';
import TertiaryNav from './HeaderPartials/TertiaryNav';
import Brand from '../UI/Brand';
import SuperUserBar from './SuperUserBar';
import { useLoginLinkClickHandler } from '../../helpers/useLoginLinkClickHandler';
import useDarkly from '../../hooks/useDarkly';
import BrowserContext from '../../context/BrowserContext';

import '../../../static/scss/components/header.scss';

type MainNavigationProps = {
  showSearchLink: boolean;
  isStatic: boolean;
  secondaryClass: string;
  isAdmin: boolean;
  user: any | undefined | null;
  theme: any;
  mobileVisible: boolean;
  secondaryVisible: boolean;
  toggleSecondary: () => void;
  toggleMobileMenu: () => void;
};

const MainNavigation = ({
  showSearchLink,
  isStatic,
  secondaryClass,
  isAdmin,
  user,
  theme,
  mobileVisible,
  secondaryVisible,
  toggleSecondary,
  toggleMobileMenu
}: MainNavigationProps) => {
  const {
    hideCPM,
    hideFindParking,
    hidePrimaryNav,
    monthlyCheckout,
    searchResults
  } = theme;
  const renderPrimaryLinks =
    hidePrimaryNav || Boolean(monthlyCheckout) || Boolean(searchResults);

  return (
    <>
      <PrimaryNav
        showSearchLink={showSearchLink}
        isStatic={isStatic}
        renderPrimaryLinks={renderPrimaryLinks}
        hideFindParking={hideFindParking}
        hideCPM={hideCPM}
        openHelpInNewTab={Boolean(monthlyCheckout)}
      />

      <SecondaryNav
        secondaryClass={secondaryClass}
        isAdmin={isAdmin}
        user={user}
        isStatic={isStatic}
        hideFindParking={hideFindParking}
        mobileVisible={mobileVisible}
        secondaryVisible={secondaryVisible}
        toggleSecondary={toggleSecondary}
        toggleMobileMenu={toggleMobileMenu}
      />
    </>
  );
};

const conditionallyRenderTertiaryNav = (location) => {
  const tertiaryDisabled = ['rent-your-space'];

  if (location && location.search) {
    const { showTertiaryNavigation, page } = querystring.parse(location.search);
    if (
      !!parseInt(showTertiaryNavigation, 10) &&
      !tertiaryDisabled.includes(page)
    ) {
      return (
        <div className="c-header__row">
          <TertiaryNav location={location} />
        </div>
      );
    }
  }
  return null;
};
type HeaderProps = {
  user?: any;
  isStatic?: boolean;
  theme?: any;
};

const headerClass = 'c-header';

export const Header = ({
  user = undefined,
  isStatic = false,
  theme = {}
}: HeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const browser = useContext(BrowserContext);
  const mobileHeader = browser.lessThan.medium;

  const [mobileVisible, setMobileVisible] = useState(false);
  const [secondaryVisible, setSecondaryVisible] = useState(false);

  const partnerName = useSelector(selectPartnerName);

  const pathNameKey = location?.key;

  useEffect(() => {
    if (pathNameKey) {
      setMobileVisible(false);
    }
  }, [pathNameKey]);

  const toggleMobileMenu = () => {
    setMobileVisible(!mobileVisible);
  };

  const toggleSecondary = (e) => {
    if (window && window.innerWidth >= 860) {
      e.preventDefault();

      setSecondaryVisible(!secondaryVisible);
    }
  };

  const isAdmin = !!(user && user.isAdmin);
  const menuContainerClass = mobileVisible
    ? `${headerClass}__menu-container--visible`
    : '';
  const secondaryClass = `${headerClass}__menu${
    secondaryVisible ? ` ${headerClass}__menu--visible` : ''
  }`;
  const userMenuClass = user ? '' : ' c-header__menu--user-no-auth';
  const partnerMenuClass = partnerName ? `${headerClass}--${partnerName}` : '';

  const {
    monthlyCheckout,
    searchResults,
    minimalBackground,
    darkMobileMenu
  } = theme;
  const monthlyCheckoutClass = monthlyCheckout ? '--monthlyCheckout' : '';
  const searchResultsClass = searchResults ? '--searchResults' : '';
  const headerBackground =
    (searchResults && !mobileHeader) || monthlyCheckout ? 'dark' : 'light';
  const darkMobileMenuClass = darkMobileMenu
    ? `${headerClass}--darkMobileMenu`
    : '';

  const tertiaryNavigation = conditionallyRenderTertiaryNav(location);
  const showSearchLink = !!tertiaryNavigation;

  const loginLinkClickHandler = useLoginLinkClickHandler();

  const hideHeaderFlag = useDarkly('srp-hide-header');

  const srpMobileHideHeader = hideHeaderFlag;

  return (
    <header
      className={`${headerClass} ${partnerMenuClass} ${
        minimalBackground ? `${headerClass}--background-minimal` : ''
      } ${
        tertiaryNavigation ? `${headerClass}--has-tertiary` : ''
      } ${headerClass}${searchResultsClass} ${headerClass}${monthlyCheckoutClass} ${darkMobileMenuClass}
      ${srpMobileHideHeader ? `${headerClass}--srp-mobile-hide-header` : ''}
      `}
    >
      <div className="c-header__row">
        <div className={`c-header__logo c-header__logo${monthlyCheckoutClass}`}>
          <span className="c-header__logo-container">
            <Logo theme={headerBackground} />
          </span>
        </div>
        <Brand wrap color>
          <MobileMenuIcon
            className={`c-header__mobile-anchor c-header__mobile-anchor${monthlyCheckoutClass}`}
            onClick={toggleMobileMenu}
          />
        </Brand>
        <div className={`c-header__menu-container ${menuContainerClass}`}>
          {mobileVisible && (
            <div className="c-header__logo-small">
              <img src={LogoSmall} alt="JustPark" width={22} height={31} />
            </div>
          )}
          <ScrollLock isActive={mobileVisible}>
            <div className="c-header__mobile-scroll">
              <MainNavigation
                showSearchLink={showSearchLink}
                isStatic={isStatic}
                secondaryClass={secondaryClass}
                isAdmin={isAdmin}
                user={user}
                theme={theme}
                mobileVisible={mobileVisible}
                secondaryVisible={secondaryVisible}
                toggleSecondary={toggleSecondary}
                toggleMobileMenu={toggleMobileMenu}
              />
            </div>
          </ScrollLock>
          <ul className={`c-header__menu c-header__menu--user${userMenuClass}`}>
            <li data-cy="login-dashbord-link">
              {user && (
                <Brand color>
                  <LegacyLink to="dashboard/" onClick={toggleSecondary}>
                    {`${user.firstName} ${
                      user.lastName && user.lastName.charAt(0).toUpperCase()
                    }`}
                    <span>{t('navigation:myDashboard', 'My Dashboard')}</span>

                    <Brand>
                      <div
                        className={`c-header__menu--user__dropdown c-header__menu--user__dropdown${monthlyCheckoutClass}`}
                      />
                    </Brand>
                  </LegacyLink>
                </Brand>
              )}
              {!user && !monthlyCheckout && (
                <Brand color>
                  <Link
                    onClick={loginLinkClickHandler}
                    to="/auth/login"
                    className="c-header__menu--login-link"
                  >
                    {t('cta:login', 'Login')}
                  </Link>
                </Brand>
              )}
            </li>
            {!user && !monthlyCheckout && (
              <li key="registration" data-cy="sign-up-link">
                <Brand color borderColor>
                  <Link
                    to="/registration"
                    className="c-header__menu--login-link"
                  >
                    {t('cta:signUp', 'Sign Up')}
                  </Link>
                </Brand>
              </li>
            )}
          </ul>
        </div>
        {mobileVisible && (
          <div
            className="c-header__menu-overlay c-header__menu-overlay--visible"
            onClick={toggleMobileMenu}
          />
        )}
      </div>
      {tertiaryNavigation}
      <SuperUserBar user={user} />
    </header>
  );
};

export default Header;
