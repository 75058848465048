import dayjs from 'dayjs';
import { transformLocale } from '@justpark/helpers/src/dates/convertToDayjsWithTimezone';

export default {
  nonExplicitSupportedLngs: true,
  keySeparator: false,
  detection: {
    lookupQuerystring: 'locale'
  },
  interpolation: {
    escapeValue: false,
    format(value, format, locale) {
      if (dayjs.isDayjs(value)) {
        return value.locale(transformLocale(locale)).format(format);
      }
      return value;
    }
  },
  react: {
    useSuspense: false
  },
  debug: false,
  ns: [
    'appFeature',
    'billing',
    'booking',
    'bookingDetails',
    'bookingPreference',
    'bookingReceived',
    'bookingsMade',
    'bookingsReceived',
    'checkout',
    'communicationSettings',
    'consents',
    'countries',
    'cta',
    'dashboard',
    'dateFormat',
    'dates',
    'deleteAccount',
    'duration',
    'evConnector',
    'evConnectorType',
    'evSignup',
    'footer',
    'global',
    'globalError',
    'homePage',
    'listing',
    'listingDetails',
    'listingPageCalendar',
    'listingPhotos',
    'login',
    'longTermDurationPicker',
    'messages',
    'monthlyCheckout',
    'navigation',
    'paymentDetails',
    'payments',
    'paymentSources',
    'personalDetails',
    'policiesPages',
    'profile',
    'promo',
    'resetPassword',
    'search',
    'searchForm',
    'searchResultsFilters',
    'seasonTicket',
    'soOnboarding',
    'spaceOwner',
    'trust',
    'trustWidget',
    'ui',
    'vehicle',
    'welshHelp'
  ],
  defaultNS: 'cta',
  fallbackLng: (code) => {
    if (!code) {
      return false;
    }

    if (code && code.startsWith('de')) {
      return 'de';
    }

    if (code && code.startsWith('cy')) {
      return 'cy';
    }

    if (code && code.startsWith('en-US')) {
      return 'en-US';
    }

    return 'en';
  }
};
