/* eslint-disable no-plusplus */
import getDayjsInstance from '@justpark/helpers/src/dates/getDayjsInstance';

/**
 * makeActionCreator is a boilerplate helper for redux,
 * which should be used when creating non-async actions.
 * usage: const actionCreator = makeActionCreator(CONSTANT_NAME, 'paramName1', 'paramName2');
 * @param type
 * @param argNames
 */
export const makeActionCreator = (type: string, ...argNames: Array<string>) => (
  ...args: Array<unknown>
): any => {
  const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
};

export const getQueryParam = (search: string, name: string) => {
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

export const getCookieValue = (name: string) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

export const dateFromPair = (day: Date, time: string) => {
  const date = new Date(day);
  const [hours, minutes, seconds] = time
    .split(':')
    .map((str) => parseInt(str, 10));
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date.toISOString();
};

export const milesToMeters = (miles: number) => Math.round(miles * 1609);

/**
 * Return an array of numbers
 *
 * @param start
 * @param end
 * @param step
 * @returns {Array}
 */
export const range = (start, end, step = 1) => {
  const arr = [];

  for (let i = 0; i < end / step; i++) {
    arr.push(i * step);
  }

  return arr;
};

/**
 * Takes an array, current index, and a value to increment the index by
 * Calculates the index, once we reach the end of the array we loop back to the start
 *
 * @param array
 * @param index
 * @param increment
 * @returns {*}
 */
export const arrayCycle = (array, index, increment) => {
  const max = array.length;
  const newIndex = index + (increment % max);

  if (newIndex > max - 1) {
    return newIndex % max;
  }

  if (newIndex < 0) {
    return max + newIndex;
  }

  return newIndex;
};

export const roundDateToNearest = (date, step) =>
  date.startOf('minute').add(step - (date.minute() % step), 'm');

/**
 * Checks if arriving on time is in the past during prebook
 * @param {Dayjs} arriving
 * @param {Dayjs} currentTime
 *
 * @returns {Dayjs} restricted arriveOn time
 */
export const restrictPastBooking = (arriving) => {
  const currentTime = getDayjsInstance();

  return arriving.isBefore(currentTime)
    ? arriving.set({
        year: currentTime.get('year'),
        month: currentTime.get('month'),
        date: currentTime.get('date'),
        hour: currentTime.get('hour'),
        minute: Math.floor(currentTime.get('minute') / 5) * 5
      })
    : arriving;
};

/**
 * Calculates the distance between two latLng values in metres
 * Code gotten from https://www.geodatasource.com/developers/javascript
 * @param {number} lat1
 * @param {number} lng1
 * @param {number} lat2
 * @param {number} lng2
 * @returns {number} Distance
 */
export const calculateDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number => {
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lng1 - lng2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  // convert to meters
  dist *= 1609.344;
  return dist;
};

export const getIdxByKey = (array: Array<any>, key: string, value: any) => {
  let indexOfTempItem = -1;
  array.find((item, idx) => {
    indexOfTempItem = idx;
    return item[key] === value;
  });
  return indexOfTempItem;
};

export const getIdxById = (array, id) => getIdxByKey(array, 'id', id);
export default {
  makeActionCreator,
  getQueryParam,
  dateFromPair,
  range
};
