import { Client, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';

const getUrqlClient = () =>
  new Client({
    url: '/graphql',
    exchanges: [
      cacheExchange({
        resolvers: {
          Query: {
            searchResult: (_, args) => ({
              __typename: 'SearchResult',
              id: args.id
            })
          }
        },
        keys: {}
      }),
      fetchExchange
    ],
    fetchOptions: {
      headers: {
        Accept: 'multipart/mixed; deferSpec=20220824, application/json'
      }
    }
  });

export default getUrqlClient;
