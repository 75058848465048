// @ts-nocheck
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import type { Node } from "react";
import styles from "./collapsibleContainer.module.scss";

type Props = {
  isOpened?: boolean;
  children?: Node;
  className?: string | null;
};
const transitionClassNames = {
  enter: styles.animate,
  exit: styles.animate
};
const CollapsibleContainer = ({
  isOpened = false,
  children = null,
  className = null
}: Props) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={isOpened}
      timeout={300}
      classNames={transitionClassNames}
      nodeRef={nodeRef}
    >
      <div
        className={`${isOpened ? styles.baseOpen : styles.base} ${
          className || ""
        }`}
        ref={nodeRef}
      >
        <div className={styles.inner}>{children}</div>
      </div>
    </CSSTransition>
  );
};

export default CollapsibleContainer;
