import _debug from 'debug';
import { createSlice } from '@reduxjs/toolkit';
import { makeActionCreator } from '../helpers/utils';

const debug = _debug('jp:reducers:geolocation');

export const GET_GEOLOCATION = 'geolocation/GET_GEOLOCATION';
export const GET_GEOLOCATION_FAILED = 'geolocation/GET_GEOLOCATION_FAILED';
export const SET_GEOLOCATION = 'geolocation/SET_GEOLOCATION';
type LatLng = {
  lat: number;
  lng: number;
};

export type State = {
  coordinates: LatLng | undefined | null;
  address: string | undefined | null;
  loading: boolean;
  failed: boolean;
};
type GET_GEOLOCATION_ACTION = { type: typeof GET_GEOLOCATION };
type GET_GEOLOCATION_FAILED_ACTION = { type: typeof GET_GEOLOCATION_FAILED };
type SET_GEOLOCATION_ACTION = {
  type: typeof SET_GEOLOCATION;
  address: string;
  coordinates: LatLng;
};

export type Action =
  | GET_GEOLOCATION_ACTION
  | SET_GEOLOCATION_ACTION
  | GET_GEOLOCATION_FAILED_ACTION;

export const initialState: State = {
  coordinates: null,
  address: null,
  loading: false,
  failed: false
};

const sliceName = 'geolocation';

const geolocationSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GET_GEOLOCATION,
      (state: State): State => {
        debug(GET_GEOLOCATION);
        return {
          ...state,
          coordinates: null,
          address: null,
          loading: true,
          failed: false
        };
      }
    );
    builder.addCase(
      GET_GEOLOCATION_FAILED,
      (state: State): State => {
        debug(GET_GEOLOCATION);

        return {
          ...state,
          coordinates: null,
          address: null,
          loading: false,
          failed: true
        };
      }
    );
    builder.addCase(
      SET_GEOLOCATION,
      (state: State, action: SET_GEOLOCATION_ACTION): State => {
        debug(SET_GEOLOCATION);

        return {
          ...state,
          address: action.address,
          coordinates: action.coordinates,
          loading: false,
          failed: false
        };
      }
    );
  }
});

export default geolocationSlice;

export const getGeolocation = makeActionCreator(GET_GEOLOCATION);
export const getlocationFailed = makeActionCreator(GET_GEOLOCATION_FAILED);

export const setGeolocation = makeActionCreator(
  SET_GEOLOCATION,
  'address',
  'coordinates'
);
