// https://youmightnotneed.com/lodash#unionBy

const uniqBy = (arr, iteratee) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    // eslint-disable-next-line no-param-reassign
    iteratee = (item) => item[prop];
  }

  return arr.filter(
    (x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y))
  );
};

export default uniqBy;
