import _debug from 'debug';
import { createSlice } from '@reduxjs/toolkit';
import { makeActionCreator } from '../helpers/utils';

const debug = _debug('jp:reducers:ui');

export const UPDATE_UI = 'ui:update';

export type State = {
  isWebview: boolean;
  flagrEntityId: string;
};

export const initialState: State = {
  isWebview: false,
  flagrEntityId: ''
};
type Action = { type: typeof UPDATE_UI; data: any };

const sliceName = 'ui';

const uiSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      UPDATE_UI,
      (state: State, action: Action): State => {
        debug(UPDATE_UI, action);

        return {
          ...state,
          ...action.data
        };
      }
    );
  }
});

export default uiSlice;

export const updateUI = makeActionCreator(UPDATE_UI, 'data');

export const selectIsWebview = (state) => state[sliceName].isWebview;
