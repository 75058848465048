// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Warning from "../../../svg/warning.svg";
import WarningSecondary from "../../../svg/warning_secondary.svg";
import "./warningIcon.scss";

type Props = {
  className?: string;
  type?: "primary" | "secondary";
};
const WarningIcon = ({ className = "", type = "primary" }: Props) => {
  const baseClass = "c-warning-icon";
  return type === "primary" ? (
    <Warning className={`${baseClass} ${className}`} />
  ) : (
    <WarningSecondary
      className={`${bem(baseClass, null, {
        type
      })} ${className}`}
    />
  );
};

export default WarningIcon;
