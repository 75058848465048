// @ts-nocheck
import { AxiosError } from "axios";

/**
 * Returns the generic error message for a request
 * This may be a network level error from an API request, or a single server side error message
 * Individual validation error messages are not returned here
 *
 * @param error
 * @returns {*|string}
 */
const getRequestError = (error: AxiosError): string => {
  return (
    error.response?.data?.meta?.message ||
    error.response?.data?.message ||
    error.response?.data?.errors ||
    error.response?.data?.error?.message ||
    error.response?.data?.data?.title ||
    // @ts-ignore
    error.response?.message ||
    error.message ||
    // @ts-ignore
    error.data?.title
  );
};

export default getRequestError;
