import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../stores/auth';
import {
  clearSensitiveData,
  setInitialVehicleState,
  setInitialPaymentState
} from '../stores/actions/shared';

const useClearDataOnLogout = () => {
  const dispatch = useDispatch();

  const userId = useSelector(selectUserId);

  useEffect(
    () => () => {
      if (userId !== undefined) {
        dispatch(setInitialPaymentState());
        dispatch(setInitialVehicleState());
        dispatch(clearSensitiveData());
      }
    },
    [dispatch, userId]
  );
};

export default useClearDataOnLogout;
