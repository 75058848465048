import Bugsnag from '@bugsnag/js';
import dbg from 'debug';
import config from '../../../config/config';
const debug = dbg('jp:bugsnag');
const clientCheck = !config.isTesting && import.meta.env.BUGSNAG_JS_API_KEY;

/**
 * Notify bugsnag of some information
 * @param error
 * @param opts
 */
export const notify = (error, opts) => {
  if (clientCheck) {
    debug('Notify error', {
      error,
      opts
    });
    return Bugsnag.notify(error, opts);
  }
  console.error(error);
  return false;
};

/**
 * Set the current bugsnag userId
 */
export const setUser = user => {
  if (clientCheck) {
    debug('Set bugsnag user');
    if (user) {
      Bugsnag.setUser(user.id, user.email, user.firstName);
      return;
    }
    Bugsnag.setUser(Bugsnag.id, null, null);
  }
};
export const leaveBreadcrumb = (name, metadata = {}) => {
  if (clientCheck) {
    debug('Leave Breadcrumb', {
      name,
      metadata
    });
    Bugsnag.leaveBreadcrumb(name, metadata);
  }
};