import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from
} from '@apollo/client';
import requestTime from '../apollo/requestTime';
import cacheConfig from '../apollo/cacheConfig';

const clientLocationMiddleware = (store) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'client-location': JSON.stringify(
          store.getState().geolocation.coordinates
        )
      }
    }));

    return forward(operation);
  });

export const getApolloClient = (() => {
  let apolloClient;
  return (store, locale) => {
    // eslint-disable-next-line no-underscore-dangle
    const apolloState = window.__APOLLO_STATE__;

    if (!apolloClient) {
      apolloClient = new ApolloClient({
        link: from([
          requestTime,
          clientLocationMiddleware(store),
          new HttpLink({
            uri: '/graphql',
            headers: {
              'Accept-Language': locale
            }
          })
        ]),
        cache: new InMemoryCache(cacheConfig).restore(apolloState)
      });
    }

    return apolloClient;
  };
})();

export type JpApolloClient = ApolloClient<any>;
