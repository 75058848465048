import { combineReducers, configureStore } from '@reduxjs/toolkit';

import _debug from 'debug';
import promiseMiddleware from './middleware/promiseMiddleware';
import facebookMiddleware from './middleware/facebookMiddleware';
import geolocationMiddleware from './middleware/geolocationMiddleware';
import nocacheMiddleware from './middleware/nocacheMiddleware';
import { actionListenersStoreEnhancer } from './components/Decorators/withAnalytics';
import reducer from './stores/reducer';
import appInterfaceMiddleware from './middleware/appInterfaceMiddleware';
import config from '../config/config';

const staticReducers = reducer();

const debug = _debug('jp:store');

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers
  });
}

export default function createStore(data, t, jpApiClient, asyncReducers = {}) {
  // Sync dispatched route actions to the history
  const thunkExtra = { t, jpApiClient };

  const enhancers = [actionListenersStoreEnhancer];

  const store = configureStore({
    reducer: createReducer(asyncReducers),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument: thunkExtra },
        serializableCheck: {
          ignoredActions: ['@@form/UPDATE'],
          ignoredPaths: [
            'model.startDate',
            'form.byId.CornwallSeasonTicketForm.model.startDate'
          ]
        }
      }).concat([
        promiseMiddleware,
        facebookMiddleware,
        geolocationMiddleware,
        nocacheMiddleware,
        appInterfaceMiddleware.middleware
      ]),
    devTools: config.isDevelopment,
    preloadedState: data,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(enhancers)
  });

  const asyncReducersStore = {
    ...asyncReducers
  };

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  const injectReducer = (slice) => {
    const key = slice.name;

    if (key in asyncReducersStore) {
      debug(`${key} already injected`);
      return;
    }

    asyncReducersStore[key] = slice.reducer;

    debug(`injecting reducer ${key}`);
    store.replaceReducer(createReducer(asyncReducersStore));
  };

  if (import.meta.env.SSR === false && window.Cypress) {
    window.store = store;
  }

  return {
    ...store,
    injectReducer
  };
}

export type ReduxStore = ReturnType<typeof createStore>;

export const possibleAsyncReducers = [
  'booking',
  'bookingPayments',
  'bookingEarnings',
  'withdrawals',
  'dashboardAvailability',
  'seasonTicketDetails',
  'evListingsChargerDetails',
  'evSignup',
  'forms/seasonTickets',
  'listingPhotos',
  'forms/profile',
  'listingOnboarding',
  'wallet',
  'search',
  'monthlyCheckout',
  'payments',
  'vehicle'
];
