/* eslint-disable import/no-cycle */
import snackbarSlice from '@justpark/snackbar/src/reducers/snackbar';
import type { State as SnackbarState } from '@justpark/snackbar/src/reducers/snackbar';
import authSlice from './auth';
import consentsSlice from './consents';
import uiSlice from './ui';
import brandSlice from './brand';
import type { State as AuthState } from './auth';
import type { State as ConsentsState } from './consents';
import type { State as UIState } from './ui';
import type { State as BrandState } from './brand';
import { reducer as form } from '../components/Decorators/form';
import geolocationSlice from './geolocation';
import type { State as GeolocationState } from './geolocation';
import partnerSlice from './partner';
import evWelcomeSlice from './evWelcome';
import promotionsSlice from './promotions';
import launchDarklySlice from './launchDarkly';
import type { State as PromotionsState } from './promotions';

export type State = {
  consents: ConsentsState;
  auth: AuthState;
  geolocation: GeolocationState;
  brand: BrandState;
  ui: UIState;
  snackbar: SnackbarState;
  promotions: PromotionsState;
};

export type Store = {
  getState: () => State;
};

export const reducers = () => ({
  [authSlice.name]: authSlice.reducer,
  [consentsSlice.name]: consentsSlice.reducer,
  form,
  [geolocationSlice.name]: geolocationSlice.reducer,
  [brandSlice.name]: brandSlice.reducer,
  [uiSlice.name]: uiSlice.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [partnerSlice.name]: partnerSlice.reducer,
  [evWelcomeSlice.name]: evWelcomeSlice.reducer,
  [promotionsSlice.name]: promotionsSlice.reducer,
  [launchDarklySlice.name]: launchDarklySlice.reducer
});

export default reducers;
