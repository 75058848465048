import { redirect } from '@not-remix-run/react';
import { selectUser } from '../stores/auth';
import { getRouterContext } from './routerContext';

const buildRedirectUrl = (pathname: string, search: string, config: any) => {
  const path = encodeURIComponent(`${pathname}${search}`);
  const extraParams =
    config && config.urlParams ? `&${config.urlParams()}` : '';

  return `/auth/login/?routerNext=${path}&is_temp=1${extraParams}`;
};

const withAuth = (loader, config) => (args) => {
  const { context, request } = args;
  const { getState, authPromise } = getRouterContext(context);

  const { url } = request;
  const { pathname, searchParams } = new URL(url);

  return authPromise.then(() => {
    const isLoggedIn = selectUser(getState());

    if (!isLoggedIn) {
      const redirectUrl = buildRedirectUrl(
        pathname,
        searchParams.toString(),
        config
      );
      return redirect(redirectUrl);
    }

    return loader(args);
  });
};

export default withAuth;
