// @ts-nocheck
const scripts: Record<string, any> = {};
/**
 * Injects a script into the page and returns a promise or it's load/error
 * Subsequent calls for the same URL return the same promise regardless of async or defer
 *
 * @param url
 * @param async
 * @param defer
 * @returns {*}
 */
const scriptLoader = (url, async = false, defer = false, attrs = []) => {
  if (url in scripts) {
    return scripts[url];
  }

  const promise = new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = async;
    script.defer = defer;

    attrs.forEach((attr) => {
      script[attr.key] = attr.value;
    });

    script.addEventListener("load", () => {
      resolve();
    });

    script.addEventListener("error", () => {
      reject();
    });

    window.document.body.appendChild(script);
  });

  scripts[url] = promise;

  return promise;
};

export const waitInWindow = (global) => {
  let interval;

  return new Promise((resolve) => {
    interval = setInterval(() => {
      if (!(global in window)) {
        return;
      }

      clearInterval(interval);
      resolve(window[global]);
    }, 100);
  });
};

export default scriptLoader;
