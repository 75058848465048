/* eslint-disable react/prop-types */
import React from 'react';
import { host } from '../../helpers/url';
import config from '../../../config/config';

export default (props) => {
  const to = props.to?.startsWith('/') ? props.to.slice(1) : props.to;
  const href = config.isDevelopment ? `${host()}/${to}` : `/${to}`;

  return (
    <a href={href} onClick={props.onClick} {...props}>
      {props.children}
    </a>
  );
};
