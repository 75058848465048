import _debug from 'debug';
import {
  geolocationToLatLng,
  geocodeLatLng
} from '@justpark/helpers/src/maps/maps';
import { getGeolocation } from '@justpark/helpers/src/geolocation/geolocation';
import {
  GET_GEOLOCATION,
  setGeolocation,
  getlocationFailed
} from '../stores/geolocation';
import type { Store } from '../stores/reducer';

const debug = _debug('jp:middleware:geolocation');
type Action = { type: typeof GET_GEOLOCATION };

const geolocationMiddleware = (store: Store) => (
  next: (...args: Array<any>) => any
) => (action: Action): any => {
  switch (action.type) {
    case GET_GEOLOCATION: {
      debug(GET_GEOLOCATION);

      getGeolocation({ maximumAge: 120000 })
        .then(geolocationToLatLng)
        .then(geocodeLatLng)
        .then((geocoderResult) => {
          const lat = geocoderResult[0].geometry.location.lat();
          const lng = geocoderResult[0].geometry.location.lng();
          const address = geocoderResult[0].formatted_address;

          store.dispatch(setGeolocation(address, { lat, lng }));
        })
        .catch(() => {
          store.dispatch(getlocationFailed());
        });

      break;
    }

    default:
      break;
  }

  return next(action);
};

export default geolocationMiddleware;
