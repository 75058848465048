// @ts-nocheck
import React, { Children, cloneElement } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import styles from "./typography.module.scss";

type Props = {
  /**
   * You may want to assign keys to the children if there are more than one
   */
  children: any;
  size?:
    | "h1"
    | "h2"
    | "h3"
    | "primary"
    | "secondary"
    | "tertiary"
    | "subtext"
    | "hero"
    | "subhead"
    | "subtext2";
  bold?: boolean;
  color?:
    | "primary"
    | "mid"
    | "light"
    | "brand"
    | "muted"
    | "error"
    | "primary-inverse"
    | "brand-secondary"
    | "low-emphasis"
    | "brand-tertiary"
    | "text-mid"
    | "text-dark"
    | "white";
  decoration?: "underline" | null;
  className?: string;
  noWrap?: boolean;
  fontStyle?: "italic" | null;
};
/**
 * The Typography component wraps other elements and adds the correct class names required to achieve the correct typographical style.
 * Any children that are text nodes will be wrapped in a span with class names applied to it.
 * Any other children that are elements will be cloned in order to append the correct class names
 *
 * It is recommended to use this components closest to any leaf nodes.
 *
 * Though this module will render text in our heading styles they have no semantic meaning. Headings should still be in a `<h1>` or other appropriate heading.
 */

const Typography = ({
  size = "primary",
  bold = false,
  children,
  color = "primary",
  className = "",
  noWrap = false,
  decoration = null,
  fontStyle = null
}: Props) => {
  const textNode = typeof children === "string";
  const bemClasses = bem(
    "c-typography",
    null,
    {
      size,
      bold,
      [`color-${color}`]: true,
      noWrap,
      decoration,
      fontStyle
    },
    styles
  );
  const classNames = `${bemClasses}${className ? ` ${className}` : ""}`;
  if (textNode) {
    return <span className={classNames}>{children}</span>;
  }
  return (
    <>
      {Children.map(children, (child) => {
        if (child === null) {
          return child;
        }
        if (typeof child !== "object") {
          return (
            <span key={child} className={classNames}>
              {child}
            </span>
          );
        }
        return cloneElement(child, {
          className: `${
            child.props.className ? `${child.props.className} ` : ""
          }${classNames}`
        });
      })}
    </>
  );
};

export default Typography;
