import React from 'react';
import { Link } from '@not-remix-run/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LegacyLink from '../../Common/LegacyLink';
import Zendesk from '../../Common/ZendeskLink';
import { selectIsAppFeatureLimited } from '../../../stores/partner';

type PrimaryLinksProps = {
  showSearchLink: boolean;
  hideFindParking: boolean;
  hideCPM: boolean;
};

export const PrimaryLinks = ({
  showSearchLink,
  hideFindParking,
  hideCPM
}: PrimaryLinksProps) => {
  const isAppFeatureLimited = useSelector(selectIsAppFeatureLimited);
  const { t, i18n } = useTranslation();

  const locale = i18n.language;
  const isWelsh = locale === 'cy';

  return (
    <>
      {!hideFindParking && !isWelsh && (
        <li className="mobile-hidden" data-cy="find-parking">
          <Link to="/">{t('navigation:findParking', 'Find parking')}</Link>
        </li>
      )}

      {showSearchLink && (
        <li className="mobile-hidden" data-cy="h-search">
          <Link to="/">{t('cta:search', 'Search')}</Link>
        </li>
      )}

      {!isAppFeatureLimited && !isWelsh && (
        <>
          <li className="mobile-hidden" data-cy="how-it-works">
            <LegacyLink to="how-it-works/overview/">
              {t('navigation:howItWorks', 'How it works')}
            </LegacyLink>
          </li>
          <li className="mobile-hidden" data-cy="rent-your-space">
            <Link to="/how-it-works/rent-out-your-driveway">
              {t('navigation:rentOutDriveway', 'Rent out your space')}
            </Link>
          </li>
        </>
      )}

      {!isAppFeatureLimited && isWelsh && (
        <li className="mobile-hidden" data-cy="rent-your-space">
          <Link to="/dashboard/listing-onboarding">
            {t('navigation:rentOutDriveway', 'Rent out your space')}
          </Link>
        </li>
      )}

      {!hideCPM && !isAppFeatureLimited && !isWelsh && (
        <li className="mobile-hidden" data-cy="h-business">
          <LegacyLink to="business/">
            {t('navigation:business', 'Business')}
          </LegacyLink>
        </li>
      )}

      {!isAppFeatureLimited && !isWelsh && (
        <>
          <li className="mobile-hidden" data-cy="h-ev">
            <LegacyLink to="ev/ev-home/">EV</LegacyLink>
          </li>
          <li className="mobile-hidden" data-cy="h-company">
            <LegacyLink to="company/overview/">
              {t('navigation:company', 'Company')}
            </LegacyLink>
          </li>
        </>
      )}
    </>
  );
};
type PrimaryNavProps = {
  showSearchLink: boolean;
  hideFindParking: boolean;
  hideCPM: boolean;
  isStatic?: boolean;
  renderPrimaryLinks: boolean;
  openHelpInNewTab: boolean;
};

const PrimaryNav = ({
  showSearchLink,
  isStatic = false,
  renderPrimaryLinks,
  hideFindParking,
  hideCPM,
  openHelpInNewTab
}: PrimaryNavProps) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const isWelsh = locale === 'cy';

  const isAppFeatureLimited = useSelector(selectIsAppFeatureLimited);

  return (
    <ul className="c-header__menu">
      {!renderPrimaryLinks && (
        <PrimaryLinks
          showSearchLink={showSearchLink}
          hideFindParking={hideFindParking}
          hideCPM={hideCPM}
        />
      )}

      <li className="mobile-hidden" data-cy="h-help">
        {isAppFeatureLimited || isWelsh ? (
          <Link to="/help">{t('cta:help', 'Help')}</Link>
        ) : (
          <Zendesk openInNewTab={openHelpInNewTab} isStatic={isStatic}>
            {t('cta:help', 'Help')}
          </Zendesk>
        )}
      </li>
    </ul>
  );
};

export default PrimaryNav;
