import { AxiosError } from "axios";
import getRequestErrorData from "./getRequestErrorData";

/**
 * Returns the error response from the API if set or and empty object
 *
 * @param error
 */
const getRequestErrorMessageCode = (error: AxiosError) => {
  const data = getRequestErrorData(error);

  return {
    message: data?.message || error?.message || error,
    meta: data?.meta,
    code: data?.error || 0
  };
};

export default getRequestErrorMessageCode;
