import type { AxiosPromise, AxiosInstance } from "axios";

const appleLogin = (
  accessToken: string,
  code: string,
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  suppressVerificationEmail: boolean
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v5/auth/apple", {
    access_token: accessToken,
    code,
    firstname: firstName,
    lastname: lastName,
    include: "booking_summary",
    suppress_verification_email: suppressVerificationEmail
  });
};

export default appleLogin;
