import React, { useContext } from 'react';
import BrandContext from '../../context/BrandContext';

type Props = {
  children?: any;
  secondary?: boolean;
  wrap?: boolean;
  disabled?: boolean;
};

const backgroundTypeStyleProperties = ['background', 'backgroundColor'];

export const BrandComponent = (props: Props) => {
  const {
    primaryColor,
    onPrimaryColor,
    secondaryColor,
    onSecondaryColor,
    isWhiteLabeled
  } = useContext(BrandContext);

  if (!props.children) {
    return null;
  }

  const { wrap = false, secondary = false, disabled = false } = props;

  if (disabled || !isWhiteLabeled) {
    return props.children;
  }

  const color = secondary ? secondaryColor : primaryColor;
  const textOnColor = secondary ? onSecondaryColor : onPrimaryColor;

  const styleProperties = Object.keys(props).filter(
    (key) =>
      key !== 'children' &&
      key !== 'secondary' &&
      key !== 'wrap' &&
      key !== 'isWhiteLabeled' &&
      props[key] === true
  );

  const styles = {};
  styleProperties.forEach((key) => {
    styles[key] = color;

    // If we're changing the background color, also update the text color
    if (backgroundTypeStyleProperties.indexOf(key) > -1) {
      styles.color = textOnColor;
    }
  });

  const classNames = wrap
    ? styleProperties.map((property) => `c-brand--${property}`)
    : [];

  classNames.push('c-brand');

  // Add custom properties
  styles['--brand-primary-color'] = primaryColor;
  styles['--brand-secondary-color'] = secondaryColor;
  styles['--brand-on-primary-color'] = onPrimaryColor;
  styles['--brand-on-secondary-color'] = onSecondaryColor;

  // update brand primary color when it changes
  styles['--brand-primary'] = primaryColor;

  if (wrap) {
    return (
      <span className={classNames.join(' ')} style={styles}>
        {props.children}
      </span>
    );
  }

  const inputReactObject = React.Children.only(props.children);

  return React.cloneElement(inputReactObject, {
    style: {
      ...inputReactObject.props.style,
      ...styles
    },
    className: `${inputReactObject.props.className} ${classNames.join(' ')}`
  });
};

export default BrandComponent;
