import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjsDuration from "dayjs/plugin/duration";

dayjs.extend(isSameOrBefore);
dayjs.extend(dayjsDuration);

const getDayjsInstance = () => dayjs();

export default getDayjsInstance;
