import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { show as showSnackbar } from '@justpark/snackbar/src/reducers/snackbar';
import EventEmitter from 'eventemitter3';
import { logout, selectUserId } from '../stores/auth';

const auth401Event = '401';
const authEventEmitter = new EventEmitter();
export const emitAuthError = () => {
  authEventEmitter.emit(auth401Event);
};

const AuthCheck = () => {
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auth401Callback = useCallback(() => {
    dispatch(
      showSnackbar(
        t(
          'login:sessionExpired',
          'Your session has expired. Please log in again'
        )
      )
    );
    dispatch({
      type: logout.fulfilled.toString()
    });
  }, [dispatch, t]);

  useEffect(() => {
    if (userId !== undefined) {
      authEventEmitter.once(auth401Event, auth401Callback);

      return () => {
        authEventEmitter.removeListener(auth401Event, auth401Callback);
      };
    }

    return () => {};
  }, [auth401Callback, userId]);

  return null;
};

export default AuthCheck;
