// @ts-nocheck
import { createSlice, PayloadAction, nanoid } from "@reduxjs/toolkit";

type ShowMessageAction = PayloadAction<{ message: string }>;

type ShowMessageWithCtaAction = PayloadAction<{
  message: string;
  cta: string;
  snackbarAction: string;
}>;

export type State = {
  message: string;
  id: string;
  cta: string;
  action: string; // JSON instead of an object,
  hasError: boolean;
};

export const initialState: State = {
  message: "",
  id: "",
  cta: "",
  action: "",
  hasError: false
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    show: (state: State, action: ShowMessageAction) => ({
      ...initialState,
      message: action.payload,
      id: nanoid()
    }),
    hide: (state: State) => ({
      ...state,
      ...initialState
    }),
    showWithError: (state: State, action: PayloadAction) => ({
      ...state,
      message: action.payload,
      hasError: true,
      id: nanoid()
    }),
    showWithCta: {
      reducer: (state: State, action: ShowMessageWithCtaAction) => {
        return {
          ...state,
          message: action.payload.message,
          id: action.payload.id,
          cta: action.payload.cta,
          action: action.payload.action
        };
      },
      prepare: (message: string, cta: string, action: PayloadAction) => ({
        payload: {
          message,
          action: JSON.stringify({
            type: action.type,
            payload: action.payload
          }),
          cta,
          id: nanoid()
        }
      })
    }
  }
});

export default snackbarSlice;
export const { show, hide, showWithError, showWithCta } = snackbarSlice.actions;
