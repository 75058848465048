import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Analytics, { gtagTrack } from '../../helpers/Tracking/Analytics';

// Analytics singleton is bound to wrapped component as a prop,
// so that methods from it may be used directly
const analytics = new Analytics();

const getValuesAndCallback = (state, observers, previousValues) =>
  Object.keys(observers).reduce((obj, key) => {
    const [selector, callback] = observers[key];

    const previousValue = previousValues[key];
    const value = selector(state);

    if (previousValue !== value) {
      callback({ value, previousValue, state, analytics });
      // eslint-disable-next-line no-param-reassign
      obj[key] = value;
    }
    return obj;
  }, previousValues);

const subscribe = (observers, invokeImmediately = false) => (store) => {
  let values = {};

  if (invokeImmediately) {
    values = getValuesAndCallback(store.getState(), observers, values);
  }

  return store.subscribe(() => {
    getValuesAndCallback(store.getState(), observers, values);
  });
};

/**
 * A store enhancer to tap into dispatch to see if we need to fire any analytics events
 * Works similar to a middleware, except it is composable,
 * and is not static like middleware are.
 * @param createStore
 * @returns {function(*=, *=, *=)}
 */
export function actionListenersStoreEnhancer(createStore) {
  return (reducer, initialState, enhancer) => {
    const actionListeners = {};
    const store = createStore(reducer, initialState, enhancer);
    const { dispatch } = store;

    store.dispatch = (action) => {
      const result = dispatch(action);
      if (
        typeof action === 'object' &&
        action.type &&
        actionListeners[action.type]
      ) {
        actionListeners[action.type].forEach((listener) =>
          listener(action, analytics, store.getState, gtagTrack)
        );
      }

      return result;
    };

    store.addActionListener = (actionType, listener) => {
      actionListeners[actionType] = (actionListeners[actionType] || []).concat(
        listener
      );

      return () => {
        actionListeners[actionType] = actionListeners[actionType].filter(
          (l) => l !== listener
        );
      };
    };

    return store;
  };
}

/**
 * Decorator component to wrap components with Analytics listening capabilities in a reactive way
 *
 * @param valueObservers - Are subscribers fired after state is updated,
 * looking specifically at value
 * @param actionListeners - Act as middleware bound to specific actions keys
 * @param invokeImmediately - Should we process immediately or just onChange
 */
const withAnalytics = (
  valueObservers,
  actionListeners,
  invokeImmediately = true
) => (BaseComponent) => {
  const WithAnalytics = (props) => {
    const store = useStore();
    const unsubscribeRef = React.useRef();
    const removeListenerRef = React.useRef([]);
    const currentRemoveListenerRef = removeListenerRef.current;

    useEffect(() => {
      const unsubscribe = subscribe(valueObservers, invokeImmediately)(store);
      unsubscribeRef.current = unsubscribe;

      Object.keys(actionListeners).forEach((actionType) => {
        const removeListener = store.addActionListener(
          actionType,
          actionListeners[actionType]
        );
        currentRemoveListenerRef.push(removeListener);
      });

      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
          currentRemoveListenerRef.forEach((removeListener) =>
            removeListener()
          );
        }
      };
    }, [store, currentRemoveListenerRef]);

    return <BaseComponent {...props} analytics={analytics} />;
  };

  const displayName =
    BaseComponent.displayName || BaseComponent.name || 'Component';
  WithAnalytics.displayName = `WithAnalytics[${displayName}]`;

  hoistNonReactStatics(WithAnalytics, BaseComponent);

  return WithAnalytics;
};

export default withAnalytics;
