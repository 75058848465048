/**
 * Api specific utility to convert between snake_case and camelCase
 * we want camelCase all the places and the BE wants snake
 */
import camelCase from "lodash.camelcase";
import snakeCase from "lodash.snakecase";

function convertKeys(obj, caseConverter) {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeys(item, caseConverter));
  }

  if (
    obj !== null &&
    typeof obj === "object" &&
    !(obj instanceof Date) &&
    !(obj instanceof Function)
  ) {
    const newObj: Record<string, any> = {};

    Object.keys(obj).forEach((key) => {
      newObj[caseConverter(key)] = convertKeys(obj[key], caseConverter);
    });

    return newObj;
  }
  return obj;
}

export const toSnakeCase = (obj) => convertKeys(obj, snakeCase);
export const toCamelCase = (obj) => convertKeys(obj, camelCase);
