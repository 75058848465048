import type { AxiosPromise, AxiosInstance } from "axios";

const register = (
  params: any,
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  recaptchaToken: string,
  suppressVerificationEmail: boolean,
  consents: Array<{
    name: string;
    accepted: boolean;
    version: number;
  }>
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v4/auth/register", {
    params,
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    auth_cookie: true,
    g_recaptcha_response: recaptchaToken,
    suppress_verification_email: suppressVerificationEmail,
    consents
  });
};

export default register;
