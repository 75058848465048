// @ts-nocheck
import { useCallback, useEffect } from "react";

const VhResizer = () => {
  const handler = useCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);
  useEffect(() => {
    handler();
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [handler]);
  return null;
};
export default VhResizer;
