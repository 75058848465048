import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import scriptLoader from '@justpark/helpers/src/scriptLoader/scriptLoader';
import { selectLanguage } from '../../stores/partner';
import useLazyLoadIntersection from '../../hooks/useLazyLoadIntersection';

const trustPilotScript =
  '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
type Props = {
  templateId?: string;
  height?: string;
  width?: string;
  theme?: 'light' | 'dark';
  zIndex?: number;
};

const TrustpilotWidget = ({
  templateId = '53aa8807dec7e10d38f59f32',
  height = '95px',
  width = '162px',
  theme = 'light',
  zIndex = 30
}: Props) => {
  const [widgetRef, triggered] = useLazyLoadIntersection(100);

  const locale = useSelector(selectLanguage);

  useEffect(() => {
    if (!triggered) {
      return;
    }

    scriptLoader(trustPilotScript, true, true, [
      {
        key: 'fetchpriority',
        value: 'low'
      }
    ])
      .then(() => {
        if (widgetRef.current !== null) {
          window.Trustpilot.loadFromElement(widgetRef.current, true);
        }
      })
      .catch((err) => {
        // Ensure rejection is caught
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [triggered, templateId, height, width, theme, widgetRef]);

  return (
    <div
      ref={widgetRef}
      className="trustpilot-widget"
      data-locale={locale === 'en-US' ? 'en' : locale}
      data-template-id={templateId}
      data-businessunit-id="53f5ba83000064000579d742"
      data-style-height={height}
      data-style-width={width}
      data-theme={theme}
      data-stars="1,2,3,4,5"
      style={{ minHeight: height, zIndex }}
    />
  );
};

export default TrustpilotWidget;
