import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@not-remix-run/react';
import LegacyLink from '../../Common/LegacyLink';

type Props = {
  styles: {
    list: string;
    navigationLinks: string;
  };
};

const ServicesLinks = ({ styles }: Props) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      <li>
        <LegacyLink
          to="business/business-accounts/overview/"
          className={styles.navigationLinks}
        >
          {t('footer:businessAccounts', 'Business accounts')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink
          to="business/car-park-solutions/overview"
          className={styles.navigationLinks}
        >
          {t('footer:carParkManagement', 'Car park management')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink to="ev/ev-home/" className={styles.navigationLinks}>
          {t('footer:ev', 'Electric vehicle charging')}
        </LegacyLink>
      </li>
      <li>
        <Link
          to="/how-it-works/rent-out-your-driveway"
          className={styles.navigationLinks}
        >
          {t('footer:rentOutDriveway', 'Rent out your driveway')}
        </Link>
      </li>
      <li>
        <LegacyLink to="ev/justcharge/" className={styles.navigationLinks}>
          {t('footer:rentOutEv', 'Rent out your EV charger')}
        </LegacyLink>
      </li>
    </ul>
  );
};

export default ServicesLinks;
