import type { AxiosPromise, AxiosInstance } from "axios";

const passwordResetConfirmed = (
  email: string,
  key: string,
  newPassword: string,
  confirmPassword: string
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.post("/v4/auth/password-reset/confirmed", {
    email,
    key,
    password: newPassword,
    password_confirmation: confirmPassword
  });
};

export default passwordResetConfirmed;
