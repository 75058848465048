import React from 'react';
import InfoBox from '@justpark/ui/src/components/InfoBox';
import Typography from '@justpark/ui/src/components/Typography';

import '../../../static/scss/components/superUserBar.scss';

type Props = {
  user: any | undefined | null;
};

const SuperUserBar = ({ user }: Props) =>
  user && user.isSuperuser ? (
    <div className="c-su-bar">
      <InfoBox type="yellow" border>
        <Typography size="secondary" color="light">
          <div>
            You are logged in as another user ({`${user.name}-#${user.id}`}).{' '}
            <strong>Please be careful. </strong>
            <a href="/admin/su/exit?next=%2Fdashboard%2Fbookings%2Fmade%2F">
              <strong>Click here</strong>
            </a>{' '}
            to return to your account or{' '}
            <a data-toggle="modal" href="/admin/su/">
              <strong>SU again.</strong>
            </a>
          </div>
        </Typography>
      </InfoBox>
    </div>
  ) : null;

export default SuperUserBar;
