// @ts-nocheck
import React from "react";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import FontAwesomeIcon from "../FontAwesomeIcon";
import getSizeClass from "../../../helpers/getFontAwesomeSizeClassname";

type Props = {
  size?: 14 | 16 | 24 | 32 | 40 | 48;
  className?: string;
};
const Facebook = ({ size, className = "" }: Props) => {
  const sizeClass = getSizeClass(size);
  return (
    <div className={`${sizeClass} ${className}`}>
      <FontAwesomeIcon icon={faFacebookF} className={sizeClass} />
    </div>
  );
};
export default Facebook;
