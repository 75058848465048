import { gtagTrack } from './Tracking/Analytics';
import { ampli } from './Tracking/amplitude';

export const loadApiClient = (locale, partnerName, emitAuthError) =>
  import('@justpark/api/src/apiClient').then(({ default: getApiClient }) =>
    getApiClient({
      baseUrl: '',
      headers: {
        'Accept-Language': locale,
        'X-JP-Partner': partnerName
      },
      errorReporter: (error) => {
        const { config: requestConfig, response } = error;

        const httpStatus = response?.status;
        const requestURL = requestConfig?.url;
        const v5ErrorCode = response?.data?.error;
        const v4ErrorCode = response?.data?.data?.code;

        const errorCode = v5ErrorCode || v4ErrorCode;

        if (httpStatus === 401) {
          emitAuthError();
        }

        if (
          httpStatus !== undefined &&
          requestURL !== undefined &&
          errorCode !== undefined
        ) {
          gtagTrack('api_error', {
            httpStatus,
            errorCode,
            requestURL
          });

          ampli.apiErrorOccurred({
            http_status_code: httpStatus,
            error_code: parseInt(errorCode, 10),
            request_url: requestURL
          });
        }
      }
    })
  );
