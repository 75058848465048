// if this withFeatureRoute decorator is being called in the same dataloader with
// a withAuth decorator, ensure that this decorator is called first
import { getRouterContext } from './routerContext';

const withFeatureRoute = (key, args) => {
  const { context } = args;
  const { getState } = getRouterContext(context);

  const isEnabled = getState().launchDarkly.entities[
    `enable-jp-web-route-${key}`
  ].value;

  if (!isEnabled) {
    throw new Response('Not Found', { status: 404 });
  }
};

export default withFeatureRoute;
