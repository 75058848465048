// @ts-nocheck
import { useEffect, useCallback } from "react";

const useOutsideClickHandler = (ref, onClose, isOpen) => {
  const handleClickOutside = useCallback(
    (e) => {
      const cookieBanner = document.getElementById("onetrust-consent-sdk");
      const targetInCookieBanner =
        cookieBanner && cookieBanner.contains(e.target);
      if (targetInCookieBanner || ref.current.contains(e.target)) {
        return;
      }
      onClose(e);
    },
    [onClose, ref]
  );
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);
};
export default useOutsideClickHandler;
