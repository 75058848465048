// @ts-nocheck
import styles from "../components/FontAwesome/iconSizes.module.scss";
import type { FontAwesomeSizes } from "../types/FontAwesome";

const getSizeClass = (size: FontAwesomeSizes) => {
  if (size === 14) {
    return styles.size14;
  }
  if (size === 16) {
    return styles.size16;
  }

  if (size === 25) {
    return styles.size25;
  }

  if (size === 32) {
    return styles.size32;
  }

  if (size === 40) {
    return styles.size40;
  }

  if (size === 48) {
    return styles.size48;
  }

  return styles.size24;
};
export default getSizeClass;
