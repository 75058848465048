const popupWindowParams = (width = 600, height = 700) => {
  const features = {
    width,
    height,
    popup: "yes",
    top: "auto",
    left: "auto",
    toolbar: "no",
    menubar: "no"
  } as const;

  return Object.entries(features)
    .reduce((str, [key, value]: [any, any]) => {
      if (value === "auto") {
        if (key === "top") {
          const v = Math.round(window.innerHeight / 2 - features.height / 2);
          return `${str}top=${v},`;
        }

        if (key === "left") {
          const v = Math.round(window.innerWidth / 2 - features.width / 2);
          return `${str}left=${v},`;
        }

        return str;
      }

      return `${str}${key}=${value},`;
    }, "")
    .slice(0, -1);
};

export default popupWindowParams;
