import { AxiosError } from "axios";

/**
 * Returns the generic error code for a request
 *
 * @param error
 * @returns {*|number}
 */
const getV4RequestErrorCode = (error: AxiosError): string =>
  error.response?.data?.data?.code;

export default getV4RequestErrorCode;
