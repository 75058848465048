import React from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import Typography from '@justpark/ui/src/components/Typography';
import LinkText from '../../UI/LinkText';
import styles from '../../../../static/scss/components/footer.module.scss';

const LowerNavigationLinks = () => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <>
      <Typography size="subtext" color="primary-inverse">
        <span>
          <Trans i18nKey="footer:copyright">
            &copy; Copyright JustPark {{ year }}
          </Trans>
        </span>
      </Typography>

      <ul className={styles.lowerNavList}>
        <li className={styles.lowerNavLinks}>
          <LinkText to="cancellation-policy" size="subtext">
            {t('footer:cancellationPolicy', 'Cancellation policy')}
          </LinkText>
        </li>
        <li className={styles.lowerNavLinks}>
          <LinkText to="/privacy-policy" size="subtext">
            {t('footer:privacyPolicy', 'Privacy Policy')}
          </LinkText>
        </li>
        <li className={styles.lowerNavLinks}>
          <LinkText to="terms-and-conditions" size="subtext">
            {t('footer:termsOfUse', 'Terms of use')}
          </LinkText>
        </li>
      </ul>
    </>
  );
};

export default LowerNavigationLinks;
