const parseCookieString = (str: string) => {
  try {
    return str
      .split(";")
      .filter((v) => v.length > 0)
      .map((v) => v.split("="))
      .reduce((acc: Record<string, any>, v: string[]) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {});
  } catch (e: any) {
    console.error(e);
    return {};
  }
};

export default parseCookieString;
