// @ts-nocheck
import React from "react";
import type { Node } from "react";
import "./linkButton.scss";

type Props = {
  children: Node;
  onClick: () => void;
  className?: string;
  cypress?: string | null;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};
const LinkButton = ({
  children,
  onClick,
  className = "",
  cypress = null,
  disabled = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}: Props) => {
  const baseClass = "c-link-button";
  return (
    <button
      className={`${baseClass} ${className}`}
      type="button"
      onClick={onClick}
      data-cy={cypress}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};

export default LinkButton;
