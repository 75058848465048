// @ts-nocheck
import React from "react";
import MobileMenu from "../../../svg/mobile-menu-icon.svg";
import "./mobileMenuIcon.scss";

type Props = {
  className?: string;
  onClick?: () => void;
};
const MobileMenuIcon = ({ className = "", onClick = () => {} }: Props) => {
  const baseClass = "c-mobile-menu-icon";
  return (
    <MobileMenu className={`${baseClass} ${className}`} onClick={onClick} />
  );
};

export default MobileMenuIcon;
