import React from 'react';
import { useTranslation } from 'react-i18next';
import LegacyLink from '../../Common/LegacyLink';

type Props = {
  styles: {
    list: string;
    navigationLinks: string;
  };
};

const InformationLinks = ({ styles }: Props) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      <li>
        <LegacyLink to="company/overview/" className={styles.navigationLinks}>
          {t('footer:aboutUs', 'About us')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink to="affiliates/" className={styles.navigationLinks}>
          {t('footer:affiliates', 'Affiliates')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink to="blog/" className={styles.navigationLinks}>
          {t('footer:blog', 'Blog')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink to="about/jobs/" className={styles.navigationLinks}>
          {t('footer:careers', 'Careers')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink to="company/newsroom/" className={styles.navigationLinks}>
          {t('footer:mediaCoverage', 'Media coverage')}
        </LegacyLink>
      </li>
      <li>
        <LegacyLink to="sitemap/" className={styles.navigationLinks}>
          {t('footer:siteMap', 'Site map')}
        </LegacyLink>
      </li>
    </ul>
  );
};

export default InformationLinks;
