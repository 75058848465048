import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const sliceName = 'launchDarkly';

type Flag = { id: string; value: any; variationIndex?: number };

const darklyAdapter = createEntityAdapter<Flag>();

const launchDarklySlice = createSlice({
  name: sliceName,
  initialState: darklyAdapter.getInitialState(),
  reducers: {
    addData: (state, action) => darklyAdapter.addMany(state, action.payload),
    setData: (state, action) => darklyAdapter.setAll(state, action.payload)
  }
});

export const { addData, setData } = launchDarklySlice.actions;

export const { selectById } = darklyAdapter.getSelectors(
  (state) => state[sliceName]
);

export const selectGoogleMapId = (state) =>
  selectById(state, 'google-map-id')?.value || '';

export const selectSODashboardFlag = (state) =>
  selectById(state, 'space-owner-dashboard')?.value || false;

export const selectOnetrust = (state) =>
  selectById(state, 'onetrust')?.value ?? true;

export const selectMessages = (state) =>
  selectById(state, 'enable-new-messages-page')?.value || false;

export default launchDarklySlice;
