import { ApolloLink } from '@apollo/client';

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: performance.now() });
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    if (data?.data?.search?.meta === undefined) {
      return data;
    }

    // data from a previous link
    const time = performance.now() - operation.getContext().start;

    const dataCopy = {
      ...data
    };

    dataCopy.data.search.meta.latency = Math.round(time);

    return dataCopy;
  })
);

const requestTime = timeStartLink.concat(logTimeLink);

export default requestTime;
