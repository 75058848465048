import React from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from '@justpark/ui/src/components/DropDown';

const options = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Cymraeg',
    value: 'cy'
  },
  {
    label: 'American',
    value: 'en-US'
  }
];

const LocaleDropdown = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const onChange = (value) => {
    window.scrollTo(0, 0);
    document.cookie = `i18next=${value}; SameSite=None; Secure; path=/`;

    const url = new URL(window.location.href);
    url.searchParams.delete('locale');
    window.location.href = url.href;
  };

  return (
    <DropDown
      onChange={onChange}
      options={options}
      value={language}
      cypress="locale-dropdown"
    />
  );
};

export default LocaleDropdown;
