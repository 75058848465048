import React from 'react';
import config from '../../config/config';

const Awin = () =>
  process.env.NODE_ENV !== 'development' ? (
    <script
      defer
      src={`https://www.dwin1.com/${config.awinAdvertiserId}.js`}
      type="text/javascript"
    />
  ) : null;

export default Awin;
