// @ts-nocheck
import React, { useMemo } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Error from "../Error";
import ChevronIcon from "../Icons/ChevronIcon";
import CrossIcon from "../Icons/CrossIcon";
import WarningIcon from "../Icons/WarningIcon";
import Typography from "../Typography";
import "./dropDown.scss";

type Props = {
  onChange: (a: string) => void;
  value?: string;
  options: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  className?: string;
  placeHolder?: string;
  label?: string;
  error?: string;
  warning?: boolean;
  name?: string | null;
  cypress?: string | null;
  id?: string;
  disabled?: boolean;
  labelColor?: string;
  hero?: boolean;
};

const DropDown = ({
  onChange,
  value = "",
  options,
  placeHolder = "",
  error = "",
  label = "",
  className = "",
  warning = false,
  name = null,
  cypress = null,
  id = "",
  disabled,
  labelColor = "light",
  hero = false
}: Props) => {
  const baseClass = "c-dropdown";
  const hasError = Boolean(error);
  const selectClassNames = bem(baseClass, "control", {
    error: hasError,
    warning
  });
  const iconClass = "c-dropdown__icon";
  const isPlaceHolderValue = value === "" && placeHolder?.length > 0;
  const selectedOption = useMemo(
    () => options.find((item) => item.value === value),
    [options, value]
  );
  return (
    <div
      id={id}
      className={`${bem(baseClass, null, {
        hero
      })} ${className}`}
    >
      <label className="c-dropdown__label">
        {label && !hero && (
          <Typography size="secondary" color={labelColor} bold>
            <div>{label}</div>
          </Typography>
        )}
        <div className={selectClassNames}>
          {hero && (
            <div className={bem(baseClass, "hero-container")}>
              {label && (
                <Typography
                  size="secondary"
                  color={labelColor}
                  className={bem(baseClass, "inner-label")}
                >
                  {label}
                </Typography>
              )}

              <Typography className={bem(baseClass, "value")} bold>
                {selectedOption?.label || placeHolder}
              </Typography>
            </div>
          )}

          {hasError && (
            <CrossIcon
              modifier="error"
              role="presentation"
              className={`${iconClass} ${iconClass}--error`}
            />
          )}
          {warning && !hasError && (
            <WarningIcon
              className={`${iconClass} ${iconClass}--warning`}
              role="presentation"
            />
          )}
          {!hasError && !warning && (
            <span className={`${iconClass} ${iconClass}--chevron`}>
              <ChevronIcon direction="down" role="presentation" />
            </span>
          )}

          <Typography color={isPlaceHolderValue ? "muted" : "primary"}>
            <select
              onChange={(e) => onChange(e.target.value)}
              value={value}
              name={name}
              className={bem(baseClass, "select")}
              data-cy={cypress}
              disabled={disabled}
            >
              {placeHolder && (
                <option key={placeHolder} value="" disabled hidden>
                  {placeHolder}
                </option>
              )}
              {options.map(
                ({
                  value: optionValue,
                  label: optionLabel,
                  disabled: optionDisabled
                }) => (
                  <option
                    key={optionValue}
                    value={optionValue}
                    disabled={optionDisabled}
                  >
                    {optionLabel}
                  </option>
                )
              )}
            </select>
          </Typography>
        </div>
      </label>
      {hasError && <Error error={error} />}
    </div>
  );
};

export default DropDown;
