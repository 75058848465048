/**
 * Is Geolocation supported in this browser
 * @type {boolean}
 */
const supportsGeolocation = () => "geolocation" in window.navigator;

/**
 * Get geolocation.
 * @param positionOptions {object} https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
 * @returns {Promise}
 * @resolve {object} https://developer.mozilla.org/en-US/docs/Web/API/Position
 * @reject {object} https://developer.mozilla.org/en-US/docs/Web/API/PositionError
 */
const getGeolocation = (positionOptions = {}) =>
  new Promise((resolve, reject) => {
    if (!supportsGeolocation()) {
      reject(new Error("Geolocation not supported"));
      return;
    }

    window.navigator.geolocation.getCurrentPosition(
      resolve,
      reject,
      positionOptions
    );
  });

export { supportsGeolocation, getGeolocation };
