// const debug = require('debug')('facebookMiddleware');

const UPDATE_LOGIN_STATUS = 'facebook/UPDATE_LOGIN_STATUS';
const LOGIN = 'facebook/LOGIN';
const LOGIN_STATUS = 'facebook/LOGIN_STATUS';
const LOGOUT = 'facebook/LOGOUT';
export const actions = {
  updateLoginStatus: () => ({
    type: UPDATE_LOGIN_STATUS
  }),
  login: () => ({
    type: LOGIN
  }),
  logout: () => ({
    type: LOGOUT
  })
};
export const events = {
  LOGIN_STATUS
};
const types = [UPDATE_LOGIN_STATUS, LOGIN, LOGOUT];
const facebookVersion = 'v12.0';
let loadFB = () => {
  const promise = new Promise(resolve => {
    const script = document.createElement('script');
    const appId = import.meta.env.FACEBOOK_API_KEY || '';
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: import.meta.env.FACEBOOK_API_KEY,
        autoLogAppEvents: true,
        version: facebookVersion
      });
      resolve();
    };
    script.textContent = `
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js#version=${facebookVersion}&appId=${appId}";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    `;
    window.document.body.appendChild(script);
    return actions.updateLoginStatus();
  });
  // Only load the script once ever
  loadFB = () => promise;
  return promise;
};
export default (store => next => action => {
  if (types.indexOf(action.type) === -1) {
    return next(action);
  }
  if (typeof window.FB === 'undefined') {
    const load = loadFB();
    return new Promise(resolve => load.then(() => store.dispatch(action).then(resolve)));
  }
  const {
    FB
  } = window;
  switch (action.type) {
    case UPDATE_LOGIN_STATUS:
      {
        const promise = new Promise(resolve => {
          FB.getLoginStatus(res => {
            store.dispatch({
              type: LOGIN_STATUS,
              payload: res
            });
            resolve(res);
          });
        });
        return promise;
      }
    case LOGIN:
      {
        return new Promise(resolve => {
          FB.login(res => {
            if (res.authResponse) {
              store.dispatch({
                type: LOGIN_STATUS,
                payload: res
              });
              resolve(res.authResponse);
            } else {
              resolve(null);
            }
          }, {
            scope: 'public_profile,email'
          });
        });
      }
    case LOGOUT:
      {
        break;
      }
    default:
      {
        return next(action);
      }
  }
  return next(action);
});